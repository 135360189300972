import React from 'react';

const NextButton = ({ onClick,darkMode }) => {
  return (
    <div className="p-2">
  <a
    href="#"
    onClick={onClick}
    
    className={`mt-10 inline-flex items-center px-3 py-1 h-[20px] text-sm font-medium text-center text-white ${darkMode ? 'bg-[#ca113d]' : 'bg-[#ca113d]'} - ${darkMode ? 'hover:bg-[#b60f34] ' : 'hover:bg-[#b60f34] '}   focus:outline-none focus:ring-[#b60f34]  rounded-[5px]`}
    >
  Kampanya detayı
  
  </a>
</div>
    
  );
};

export default NextButton;
