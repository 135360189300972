import { combineReducers } from 'redux';
import userReducer from './userReducer';
import contactReducer from './contactReducer';
import tabReducer from './tabRedujer';
import scopeReducers from './scopeReucers';
import locationReducer from './locationReducer';
import topicReducer from './topicReducer';
import campaignReducer from './campaignReducer';
import imageReducer from './imageRedujer';
import aiCampaignQuestionsReducer from './aiCampaignQuestionsReducer';


const rootReducer = combineReducers({
    users: userReducer,
    contact: contactReducer,
    tab: tabReducer,
    scopeReducers,
    locationReducer,
    topic:topicReducer,
    campaignReducer,
    imageReducer,
    aiCampaignQuestionsReducer
});

export default rootReducer;