
import storeConstants from "../constans/actionTypes";

const initialState = {
  uploadedFiles: [], 
};

const imageReducer = (state = initialState, action) => {
  switch (action.type) {
    case storeConstants.SET_UPLOADED_FILE:
      console.log("Reducer payload:", action.payload); 
      return {
        ...state,
        uploadedFiles: action.payload, 
      };
    case storeConstants.RESET_CAMPAIGN_STATE:
      return initialState;
    default:
      return state;
  }
};

export default imageReducer;
