import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import storeConstants from '../redux/constans/actionTypes';

const topics = [
  'Hayvan Hakları',
  'Çevre',
  'Ceza Adaleti',
  'Çocuk ve Aile Refahı',
  'Din',
  'Eğitim',
  'Engelliler',
  'Göçmenler',
  'Kadın Hakları',
  'Kamu Politikası',
  'Kültür ve Sanat ve Medya',
  'Cinsiyet Eşitsizliği',
  'Politika',
  'Sağlık',
  'Spor',
  'Teknoloji ve İnternet',
  'Yaşlı Hakları',
  'Diğer'
];

const formatTopic = (topic) => {
  if (topic === 'Hayvan Hakları') {
    return 'hayvan_hakları';
  }
  return topic.toLowerCase().replace(/ /g, '_');
};

export default function TopicSelector({ darkMode }) {
  const dispatch = useDispatch();
  const selectedTopic = useSelector((state) => state.topic.selectedTopic);

  const handleButtonClick = (topic) => {
    dispatch({
      type: storeConstants.SET_SELECTED_TOPIC,
      payload: formatTopic(topic),
    });
  };

  return (
    <div className={`flex flex-col items-center w-full`}>
      <div className="flex flex-col sm:flex-row gap-4 w-full">
        <div className="w-full sm:text-start">
          <p className="text-lg sm:text-xl">Duyuru İçin En Uygun Konu Başlığını Seçin:</p>
        </div>
      </div>
      <div className="grid grid-cols-2 mt-4 sm:grid-cols-3 md:grid-cols-4 gap-4 w-full sm:pt-4">
        {topics.map((topic) => (
          <button
            key={topic}
            onClick={() => handleButtonClick(topic)}
            className={`text-xs sm:text-sm md:text-base p-3 cursor-pointer rounded-lg transition-all duration-300 
              ${selectedTopic === formatTopic(topic) ? 'bg-blue-600 text-white' : (darkMode ? 'bg-gray-700 text-white' : 'bg-gray-200 text-gray-800')} 
              ${selectedTopic === formatTopic(topic) ? 'hover:bg-blue-700' : (darkMode ? 'hover:bg-gray-600' : 'hover:bg-gray-300')}`}
          >
            {topic}
          </button>
        ))}
      </div>
    </div>
  );
}
