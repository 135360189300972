import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import storeConstants from "../../redux/constans/actionTypes";

const Hero = ({ darkMode }) => {
    const navigate = useNavigate();

    const dispatch = useDispatch();

    const handleButtonClick = () => {
        dispatch({
            type: storeConstants.RESET_CAMPAIGN_STATE,
        })
        dispatch({
            type: storeConstants.SET_BOOLEAN_CAMPAIGN,
            payload: true
        })
        navigate("/campaign")
    };



    return (
<div
  className={`w-screen px-4 pt-28 pb-16 mt-16 text-center ${
    darkMode
      ? 'bg-gradient-to-b from-[#091840] via-[#091840] to-white text-white'
      : 'bg-gradient-to-b from-blue-600 via-slate-20 to-white text-slate-900'
  }`}
>            <p className={`mx-auto -mt-4 max-w-2xl text-lg tracking-tight ${darkMode ? 'text-slate-400' : 'text-slate-700'} sm:mt-6`}>
                Haksızlığa uğramış ama sesi duyulmayan tüm canlılar için
            </p>

            <h1 className={`mx-auto max-w-4xl font-display text-5xl font-medium tracking-tight sm:text-7xl ${darkMode ? 'text-white' : 'text-slate-900'}`}>
                <span className="inline-block">
                    Tüm vicdanlı insanların
                    <span className="relative whitespace-nowrap text-blue-600">
                        <svg
                            aria-hidden="true"
                            viewBox="0 0 418 42"
                            className="absolute top-2/3 left-0 h-[0.58em] w-full fill-blue-300/70"
                            preserveAspectRatio="none"
                        >
                            <path d="M203.371.916c-26.013-2.078-76.686 1.963-124.73 9.946L67.3 12.749C35.421 18.062 18.2 21.766 6.004 25.934 1.244 27.561.828 27.778.874 28.61c.07 1.214.828 1.121 9.595-1.176 9.072-2.377 17.15-3.92 39.246-7.496C123.565 7.986 157.869 4.492 195.942 5.046c7.461.108 19.25 1.696 19.17 2.582-.107 1.183-7.874 4.31-25.75 10.366-21.992 7.45-35.43 12.534-36.701 13.884-2.173 2.308-.202 4.407 4.442 4.734 2.654.187 3.263.157 15.593-.78 35.401-2.686 57.944-3.488 88.365-3.143 46.327.526 75.721 2.23 130.788 7.584 19.787 1.924 20.814 1.98 24.557 1.332l.066-.011c1.201-.203 1.53-1.825.399-2.335-2.911-1.31-4.893-1.604-22.048-3.261-57.509-5.556-87.871-7.36-132.059-7.842-23.239-.254-33.617-.116-50.627.674-11.629.54-42.371 2.494-46.696 2.967-2.359.259 8.133-3.625 26.504-9.81 23.239-7.825 27.934-10.149 28.304-14.005.417-4.348-3.529-6-16.878-7.066Z"></path>
                        </svg>
                        <span className="relative">ortak sesi&nbsp;</span>
                    </span>
                </span>
            </h1>

            <a
    href="#"
    onClick={handleButtonClick}
    
    className={`mt-10 inline-flex items-center px-6 py-2 text-sm font-medium text-center text-white ${darkMode ? 'bg-[#ca113d]' : 'bg-[#ca113d]'}  ${darkMode ? 'hover:bg-[#b60f34] ' : 'hover:bg-[#b60f34] '}   focus:outline-none focus:ring-[#b60f34] rounded-[5px] `}
    >
    
                Yeni bir kampanya başlat
                <svg
                    className="-mr-1 ml-2 h-4 w-4"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        fillRule="evenodd"
                        d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z"
                        clipRule="evenodd"
                    ></path>
                </svg>
            </a>
        </div>
    );
};

export default Hero;
