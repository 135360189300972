import React from 'react'
import { IntlProvider } from 'react-intl'
import en from './en.json'
import tr from './tr.json'
import cn from './cn.json'
import de from './de.json'
import es from './es.json'
import fr from './fr.json'
import pt from './pt.json'
import ru from './ru.json'
import sa from './sa.json'

const LangProvider = ({ children }) => {
  const initialState = {
    selectedLang: 'tr',
  }

  function getConfig() {
    const ls = localStorage.getItem('selectedLang');
    if (ls) {
      try {
        return JSON.parse(ls);
      } catch (err) {
        console.error(err);
      }
    }
    localStorage.setItem('selectedLang', JSON.stringify(initialState)); // Set default language
    return initialState;
  }
  const locale = getConfig().selectedLang
  const allMessages = {
    en,
    tr,
    cn,
    de,
    es,
    fr,
    pt,
    ru,
    sa,
  }

  return (
    <IntlProvider locale={locale} messages={allMessages[locale]}>
      {children}
    </IntlProvider>
  )
}

export default LangProvider
