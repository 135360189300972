import React, { useEffect, useState,useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Typography from '@mui/material/Typography';
import CircularProgress from '@mui/material/CircularProgress';
import { useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import { createCampaign, generateCampaignMail, updateCampaign } from '../redux/ApiCalls';
import storeConstants from '../redux/constans/actionTypes';
import StepNavigation from '../components/campaign/stepNavigation';
import Scope from '../components/Scope';
import TopicSelector from '../components/TopicSelector';
import LocationAutocomplete from '../components/LocationAutocomplete';
import CampaignTextCreator from '../components/CampaignTextCreator';
import ImageUpload from '../components/ImageUpload';
import ContactForm from '../components/ContactForm';
import AiCampaignQuestions from '../components/AiCampaignQuestions';
import { isEqual } from 'lodash';

const getSteps = (scope) => {
  return scope === 'Yerel'
    ? ['Kapsam Seç', 'Konum Seç', 'Konu Seç', 'Kampanya Metni Yaz', 'Resim Veya Video Yükle', 'Email Gönderme Formu']
    : ['Kapsam Seç', 'Konu Seç', 'Kampanya Metni Yaz', 'Resim Veya Video Yükle', 'Email Gönderme Formu'];
};

export default function HorizontalLinearStepper({ onClose, darkMode }) {
  const dispatch = useDispatch();
  const selectedScope = useSelector((state) => state.scopeReducers.selectedScope);
  const inputValueLocation = useSelector((state) => state.locationReducer.inputValueLocation);
  const selectedTopic = useSelector((state) => state.topic.selectedTopic);
  const { templateText, campaignTitle, institutionOrPersonName } = useSelector((state) => state.campaignReducer);
  const aiCampaignQuestionsReducer = useSelector((state) => state.aiCampaignQuestionsReducer);
  const formDataMailto = useSelector(state => state.campaignReducer.formData);
  const token = useSelector((state) => state?.users?.token);
  const campaignId = useSelector((state) => state?.campaignReducer?.campaignId);
  const baseUrl = process.env.REACT_APP_REST_URL;
  const navigate = useNavigate();
  const { id } = useParams();
  const [activeStep, setActiveStep] = useState(0);
  const [isUpdateMode, setIsUpdateMode] = useState(false);
  const [loading, setLoading] = useState(false);
  const [titleError, setTitleError] = useState('');
  const steps = getSteps(selectedScope);
  const userId = useSelector((state) => state?.users?.userId);
  const uploadedFile = useSelector((state) => state.campaignReducer.uploadedFiles);
  const [isMailGenerated, setIsMailGenerated] = useState(false);
  const prevFormDataRef = useRef();

  useEffect(() => {
    if (id) {
      setIsUpdateMode(true);
      setLoading(true);
      axios.get(`${baseUrl}campaign/${id}/`)
        .then(response => {
          console.log(response.data, "response.data");
          dispatch({
            type: storeConstants.SELECT_SCOPE,
            payload: response.data.scope || 'Yerel',
          });
        })
        .catch(error => {
          console.error('Kampanya verileri alınırken hata:', error);
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      if (!selectedScope) {
        dispatch({
          type: storeConstants.SELECT_SCOPE,
          payload: 'Yerel',
        });
      }
      setIsUpdateMode(false);
    }
  }, [id, baseUrl]);



  const createCampaignHandler = async () => {
    if (token) {
      setLoading(true);
      const formData = new FormData();

      formData.append('scope', selectedScope);
      formData.append('location', inputValueLocation);
      formData.append('topic', selectedTopic);
      formData.append('title', campaignTitle);
      formData.append('content', templateText);
      formData.append('institution_or_person_name', institutionOrPersonName);
      formData.append('phone_number', formDataMailto.phone_number);
      formData.append('email', formDataMailto.email);
      formData.append('cc', formDataMailto.cc);
      formData.append('bcc', formDataMailto.bcc);
      formData.append('subject', campaignTitle);
      formData.append('message', templateText);

      if (uploadedFile && uploadedFile.length > 0) {
        uploadedFile.forEach((file) => {
          formData.append('uploaded_media', file);
        });
      }

      try {
        await createCampaign(formData, token);
        navigate('/');
      } catch (error) {
        console.error('Error creating campaign:', error);
      } finally {
        setLoading(false);
      }
    } else {
      navigate('/register');
    }
  };

  const updateCampaignHandler = async () => {
    if (token && campaignId) {
      setLoading(true);
      const formData = new FormData();
      formData.append('scope', selectedScope);
      formData.append('location', inputValueLocation);
      formData.append('topic', selectedTopic);
      formData.append('title', campaignTitle);
      formData.append('content', templateText);
      formData.append('institution_or_person_name', institutionOrPersonName);
      formData.append('phone_number', formDataMailto.phone_number);
      formData.append('email', formDataMailto.email);
      formData.append('cc', formDataMailto.cc);
      formData.append('bcc', formDataMailto.bcc);
      formData.append('subject', campaignTitle);
      formData.append('message', templateText);
      if (uploadedFile && uploadedFile.length > 0) {
        uploadedFile.forEach((file) => {
          formData.append('uploaded_media', file);
        });
      }
      try {
        await updateCampaign(campaignId, formData, token);
        onClose();
      } catch (error) {
        console.error('Error updating campaign:', error);
      } finally {
        setLoading(false);
      }
      navigate(`/user/${userId}`);
    }
  };


  const handleGenerateMail = async () => {
    if (isMailGenerated) {
      return;
    }

    try {
      const response = await generateCampaignMail(formDatas);
      dispatch({ type: storeConstants.SET_LOADING, payload: true });

      setIsMailGenerated(true);
      dispatch({
        type: storeConstants.SET_CAMPAIGN_TEXT,
        payload: { campaignTitle: response?.mail?.campaign_title, templateText: response?.mail?.campaign_text }
      });
        dispatch({
          type: storeConstants.SET_CAMPAIGN_FORM_DATA,
          payload: { "message": response?.mail.petition_text},
        });
    } catch (error) {
      if (error.response) {
        dispatch({ type: storeConstants.SET_LOADING, payload: false });
        alert(`Bir hata oluştu: ${error.response.data.message || 'Mail gönderilemedi.'}`);
      } else {
        alert('Bir hata oluştu. Mail gönderilemedi.');
      }
    }
  };

  const campaignTypes = ['Farkındalık Kampanyası', 'Değişim Kampanyası', 'Şikayet Kampanyası'];

  const formDatas = {
    campaign_type: aiCampaignQuestionsReducer.selectedAiInfo,

    ...(campaignTypes.includes(aiCampaignQuestionsReducer.selectedAiInfo) && aiCampaignQuestionsReducer.selectedAiInfo === 'Farkındalık Kampanyası' && {
      institution_or_person_name: aiCampaignQuestionsReducer.formData?.awarenessTargetInstitution,
      campaign_topic: aiCampaignQuestionsReducer.formData?.awarenessCampaignTopic,
      event_description: aiCampaignQuestionsReducer?.formData?.mainMessages,
      event_place: aiCampaignQuestionsReducer?.timeData?.eventTime,

    }),

    ...(aiCampaignQuestionsReducer.selectedAiInfo === 'Değişim Kampanyası' && {
      campaign_topic: aiCampaignQuestionsReducer.formData?.changeSubject,
      institution_or_person_name: aiCampaignQuestionsReducer.formData?.changeTargetInstitution,
      event_description: aiCampaignQuestionsReducer?.formData?.changeMainMessages,
      event_place: aiCampaignQuestionsReducer?.timeData?.eventTime,

      other_fields: {
        victims: aiCampaignQuestionsReducer?.formData?.affectedPeople.split(", "),
        perpetrators: [aiCampaignQuestionsReducer?.formData?.perpetrators],
        desired_output_of_petition: aiCampaignQuestionsReducer?.formData?.request,
        related_laws: aiCampaignQuestionsReducer?.formData?.regulationViolation.split(", "),
        event_time: aiCampaignQuestionsReducer?.timeData?.eventTime

      }
    }),

    ...(aiCampaignQuestionsReducer.selectedAiInfo === 'Şikayet Kampanyası' && {
      event_place: `${aiCampaignQuestionsReducer?.formData?.city || ''}/${aiCampaignQuestionsReducer?.formData?.district || ''} ${aiCampaignQuestionsReducer?.formData?.neighborhood || ''} ${aiCampaignQuestionsReducer?.formData?.street || ''}`,
      event_description: aiCampaignQuestionsReducer?.formData?.eventDescription,
      institution_or_person_name: aiCampaignQuestionsReducer?.formData?.complaintTargetInstitution,

      other_fields: {
        victims: aiCampaignQuestionsReducer?.formData?.affectedPeople.split(", "),
        perpetrators: [aiCampaignQuestionsReducer?.formData?.perpetrators],
        desired_output_of_petition: aiCampaignQuestionsReducer?.formData?.request,
        related_laws: aiCampaignQuestionsReducer?.formData?.regulationViolation.split(", "),
        event_time: aiCampaignQuestionsReducer?.timeData?.eventTime

      }
    }),
  };
  const isAwarenessFormValid = () => {
    const { formData} = aiCampaignQuestionsReducer;
    return (
      formData?.awarenessTargetInstitution &&
      formData?.awarenessCampaignTopic &&
      formData?.mainMessages 
    );
  };
  
  const isChangeFormValid = () => {
    const { formData } = aiCampaignQuestionsReducer;
    return (
      formData?.changeTargetInstitution &&
      formData?.changeSubject &&
      formData?.changeMainMessages 
    );
  };
  
  const isComplaintFormValid = () => {
    const { formData, timeData } = aiCampaignQuestionsReducer;
    return (
      formData?.complaintTargetInstitution &&
      formData?.eventDescription &&
      timeData?.eventTime
    );
  };
  
  const isFormValid = () => {
    const { selectedAiInfo } = aiCampaignQuestionsReducer;
  
    if (selectedAiInfo === 'Farkındalık Kampanyası') {
      return isAwarenessFormValid();
    }
  
    if (selectedAiInfo === 'Değişim Kampanyası') {
      return isChangeFormValid();
    }
  
    if (selectedAiInfo === 'Şikayet Kampanyası') {
      return isComplaintFormValid();
    }
  
    return false; 
  };
  

  const [changeData, setChangeData] = useState(false);

  useEffect(() => {
    if (prevFormDataRef.current) {
      const isFormDataChanged = !isEqual(prevFormDataRef.current, formDatas); // Use lodash's deep equality check
      console.log("Form data değişti mi?", isFormDataChanged);

      if (isFormDataChanged) {
        setChangeData(true);
      }
    }

    prevFormDataRef.current = formDatas;

  }, [formDatas]);

  

  const handleNext = () => {
   if (activeStep === 2 && changeData) {
      console.log("Form data değişti, mail gönderiliyor...");
      handleGenerateMail();
    } 
    if (activeStep === 0 && !selectedScope) {
      alert('Lütfen bir kapsam seçin.');
      return;
    }

    if (activeStep === steps.length - 1) {
      isUpdateMode ? updateCampaignHandler() : createCampaignHandler();
    } else {
      setActiveStep(prevActiveStep => prevActiveStep + 1);
    }
  };


  const handleBack = () => {
    setActiveStep(prevActiveStep => prevActiveStep - 1);
  };

  const handleContactFormChange = (formData) => {
  };

  const handleTitleErrorChange = (error) => {
    setTitleError(error);
  };



  return (
    <div className={`w-screen px-4 pb-8  mt-16 flex flex-col h-[100vh] items-center ${darkMode ? 'bg-[#091840] text-white' : 'bg-white text-black'}`}>
      <div className="flex flex-col items-center w-full max-w-[960px] mt-8 mx-auto justify-center">
        <StepNavigation steps={steps} activeStep={activeStep} darkMode={darkMode} />

        {loading ? (
          <div className="flex justify-center items-center h-full">
            <CircularProgress />
          </div>
        ) : (
          <React.Fragment>
            {activeStep === steps.length ? (
              <Typography className="mt-2 mb-1 text-sm sm:text-base md:text-xl text-center">Tüm adımlar tamamlandı - işiniz bitti</Typography>
            ) : (
              <div className={`flex flex-col items-center w-full mb-6  ${darkMode ? 'bg-[#091840]  text-white' : 'bg-white text-black'}`}>
                <h1 className="text-3xl font-bold mb-6 text-center">Adım {activeStep + 1} / {steps.length}</h1>

                <React.Fragment>
                  {activeStep === 0 && <Scope darkMode={darkMode} />}
                  {activeStep === 1 && selectedScope === 'Yerel' && <LocationAutocomplete darkMode={darkMode} />}
                  {/* {activeStep === 2 && selectedScope === 'Yerel' && <TopicSelector darkMode={darkMode} />} */}
                  {activeStep === 2 && selectedScope === 'Yerel' && <AiCampaignQuestions />}
                  {activeStep === 3 && selectedScope === 'Yerel' && <ImageUpload />}
                  {activeStep === 4 && selectedScope === 'Yerel' && <CampaignTextCreator onTitleErrorChange={handleTitleErrorChange} darkMode={darkMode} />}

                  {/* {activeStep === 4 && selectedScope === 'Yerel' && <ImageUpload />} */}
                  {activeStep === 5 && selectedScope === 'Yerel' && <ContactForm onFormChange={handleContactFormChange} darkMode={darkMode} />}

                  {activeStep === 1 && selectedScope === 'Ulusal' && <TopicSelector />}
                  {activeStep === 2 && selectedScope === 'Ulusal' && <CampaignTextCreator onTitleErrorChange={handleTitleErrorChange} />}
                  {activeStep === 3 && selectedScope === 'Ulusal' && <ImageUpload />}
                  {activeStep === 4 && selectedScope === 'Ulusal' && <ContactForm onFormChange={handleContactFormChange} />}

                  {activeStep === 1 && selectedScope === 'Küresel' && <TopicSelector />}
                  {activeStep === 2 && selectedScope === 'Küresel' && <CampaignTextCreator onTitleErrorChange={handleTitleErrorChange} />}
                  {activeStep === 3 && selectedScope === 'Küresel' && <ImageUpload />}
                  {activeStep === 4 && selectedScope === 'Küresel' && <ContactForm onFormChange={handleContactFormChange} />}

                  <div className="mt-2 flex w-full justify-between items-center gap-x-2">
                    <button disabled={activeStep === 0

                    }

                      onClick={handleBack} type="button" className="py-2 px-3 inline-flex items-center gap-x-1 text-sm font-medium rounded-lg border border-gray-200 bg-white text-gray-800 shadow-sm hover:bg-gray-50 focus:outline-none disabled:opacity-50 disabled:pointer-events-none">
                      Geri
                    </button>
                    <button disabled={
                      (activeStep === 1 && selectedScope === 'Yerel' && inputValueLocation === '') ||
                      (activeStep === 5 && selectedScope === 'Yerel' && (!templateText || !campaignTitle || !institutionOrPersonName || titleError)) ||
                      (activeStep === 3 && selectedScope === 'Yerel' && uploadedFile.length < 1) ||
                      (activeStep === 1 && selectedScope === 'Ulusal' && !selectedTopic) ||
                      (activeStep === 2 && selectedScope === 'Ulusal' && (!templateText || !campaignTitle || !institutionOrPersonName || titleError)) ||
                      (activeStep === 3 && selectedScope === 'Ulusal' && uploadedFile.length < 1) ||
                      (activeStep === 1 && selectedScope === 'Küresel' && !selectedTopic) ||
                      (activeStep === 2 && selectedScope === 'Küresel' && (!templateText || !campaignTitle || !institutionOrPersonName || titleError)) ||
                      (activeStep === 3 && selectedScope === 'Küresel' && uploadedFile.length < 1) ||
                      !selectedScope ||     (activeStep === 2 && !isFormValid()) 
                      
                    }
                      onClick={handleNext} type="button" className="py-2 px-3 inline-flex items-center gap-x-1 text-sm font-medium rounded-lg border border-transparent bg-blue-600 text-white hover:bg-blue-700 focus:outline-none disabled:opacity-50 disabled:pointer-events-none">
                      {activeStep === steps.length - 1 ? 'Kaydet' : 'Sonraki'}
                    </button>
                  </div>

                </React.Fragment>
              </div>

            )}
          </React.Fragment>
        )}
      </div>
    </div>
  );
}
