// import React, { useState, useEffect } from 'react';
// import { useDispatch, useSelector } from 'react-redux';
// import { createCampaign, createUser, fetchUserProfile, login } from '../redux/ApiCalls';
// import { TextField, Button, Container, Typography, Paper, Link } from '@mui/material';
// import { useNavigate } from 'react-router-dom';
// import storeConstants from '../redux/constans/actionTypes';

// const CreateUser = () => {
//     const dispatch = useDispatch();
//     const navigate = useNavigate();

//     const selectedScope = useSelector((state) => state.scopeReducers.selectedScope);
//     const inputValueLocation = useSelector((state) => state.locationReducer.inputValueLocation);
//     const selectedTopic = useSelector((state) => state.topic.selectedTopic);
//     const { campaignTitle } = useSelector((state) => state.campaignReducer);
//     const { customText, templateText, formData: formDataMailto } = useSelector((state) => state.campaignReducer);
//     const uploadedFile = useSelector((state) => state.imageReducer.uploadedFile);
//     const { first_name, last_name } = useSelector((state) => state.users);

//     const [formData, setFormData] = useState({
//         username: '',  
//         first_name: '',
//         last_name: '',
//         email: '',
//         password: '',
//         password2: ''
//     });

//     const [error, setError] = useState('');
//     const [success, setSuccess] = useState('');

//     useEffect(() => {
//         if (first_name) {
//             setFormData(prevFormData => ({
//                 ...prevFormData,
//                 first_name: first_name,
//                 last_name: last_name || '', 
//             }));
//         }
//     }, [first_name, last_name]);

//     const handleChange = (e) => {
//         const { name, value } = e.target;

//         const sanitizedValue = name === 'username' || name === 'first_name' || name === 'last_name' ? value.replace(/\s+/g, '') : value;

//         setFormData({
//             ...formData,
//             [name]: sanitizedValue,
//         });
//     };

//     const handleSubmit = (e) => {
//         e.preventDefault();
//         setError('');
//         setSuccess('');

//         if (formData.password !== formData.password2) {
//             setError('Passwords do not match.');
//             return;
//         }


//         createUser({
//             username: formData.username,  // Use username field
//             email: formData.email,
//             first_name: formData.first_name,
//             last_name: formData.last_name,
//             password: formData.password,
//             password2: formData.password2
//         })
//             .then(userResponse => {
//                 return login({
//                     email: formData.email,
//                     password: formData.password
//                 })
//                     .then(loginResponse => {
//                         const token = loginResponse.data.access;
//                         const formData = new FormData();
//                         formData.append('scope', selectedScope);
//                         formData.append('location', inputValueLocation);
//                         formData.append('topic', selectedTopic);

//                         if (campaignTitle) {
//                             formData.append('title', campaignTitle);
//                         }
//                         formData.append('custom_text', customText);
//                         formData.append('template_text', templateText);
//                         formData.append('phone_number', formDataMailto.phone_number);
//                         formData.append('email', formDataMailto.email);
//                         formData.append('cc', formDataMailto.cc);
//                         formData.append('bcc', formDataMailto.bcc);
//                         formData.append('subject', formDataMailto.subject);
//                         formData.append('message', formDataMailto.message);

//                         if (uploadedFile) {
//                             formData.append('file', uploadedFile);
//                         }

//                         const createCampaignPromise = (campaignTitle && token) ? createCampaign(formData, token) : Promise.resolve();

//                         return createCampaignPromise
//                             .then(() => {
//                                 return fetchUserProfile(token)
//                                     .then(response => {
//                                         const userId = response.data.user_id;
//                                         dispatch({
//                                             type: storeConstants.SET_USER_ID,
//                                             payload: userId,
//                                         });
//                                         dispatch({
//                                             type: storeConstants.SET_TOKEN,
//                                             payload: token,
//                                         });
//                                         setSuccess('User and campaign (if applicable) created successfully!');
//                                         navigate(`/user/${userId}/`); // Navigate to the dynamic path
//                                     })
//                                     .catch(error => {
//                                         setError('Error fetching user profile: ' + (error.response?.data?.detail || error.message));
//                                     });
//                             })
//                             .catch(error => {
//                                 setError('Error creating campaign: ' + (error.response?.data?.detail || error.message));
//                             });
//                     });
//             })
//             .catch(error => {
//                 setError('Error creating user or logging in: ' + (error.response?.data?.detail || error.message));
//             });
//     };

//     return (
//         <Container component="main" maxWidth="xs">
//             <Paper elevation={3} style={{ padding: '20px', marginTop: '20px' }}>
//                 <Typography variant="h5" component="h1" align="center">
//                     Kayıt Ol
//                 </Typography>
//                 <form onSubmit={handleSubmit} style={{ marginTop: '20px' }}>
//                     <TextField
//                         label="Kullanıcı Adı"  // Username field label
//                         name="username"
//                         value={formData.username}
//                         onChange={handleChange}
//                         fullWidth
//                         required
//                         variant="outlined"
//                         inputProps={{
//                             pattern: "\\S+",  // Prevents whitespace
//                             title: "Boşluk bırakamazsınız"  // Warning for whitespace
//                         }}
//                         sx={{mt:2}}
//                     />
//                     <TextField
//                         label="Ad"  // First name input
//                         name="first_name"
//                         value={formData.first_name}
//                         onChange={handleChange}
//                         fullWidth
//                         variant="outlined"
//                         sx={{mt:2}}
//                     />
//                     <TextField
//                         label="Soyad"  // Last name input
//                         name="last_name"
//                         value={formData.last_name}
//                         onChange={handleChange}
//                         fullWidth
//                         variant="outlined"
//                         sx={{mt:2}}
//                     />
//                     <TextField
//                         label="E-Posta"
//                         name="email"
//                         value={formData.email}
//                         onChange={handleChange}
//                         fullWidth
//                         required
//                         type="email"
//                         variant="outlined"
//                         sx={{mt:2}}
//                     />
//                     <TextField
//                         label="Şifre"
//                         name="password"
//                         value={formData.password}
//                         onChange={handleChange}
//                         fullWidth
//                         required
//                         type="password"
//                         variant="outlined"
//                         sx={{mt:2}}
//                     />
//                     <TextField
//                         label="Şifre Tekrar"
//                         name="password2"
//                         value={formData.password2}
//                         onChange={handleChange}
//                         fullWidth
//                         required
//                         type="password"
//                         variant="outlined"
//                         sx={{mt:2}}
//                     />
//                     <Button
//                         type="submit"
//                         variant="contained"
//                         color="primary"
//                         fullWidth
//                         sx={{mt:2}}
//                     >
//                         Kullanıcı Oluştur
//                     </Button>
//                     <Typography align="center" style={{ marginTop: '10px' }}>
//                         <Link href="/login" variant="body2">
//                             Bir hesabınız yok mu? Giriş
//                         </Link>
//                     </Typography>
//                 </form>
//                 {error && <Typography color="error" align="center" style={{ marginTop: '10px' }}>{error}</Typography>}
//                 {success && <Typography color="primary" align="center" style={{ marginTop: '10px' }}>{success}</Typography>}
//             </Paper>
//         </Container>
//     );
// };

// export default CreateUser;


import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createCampaign, createUser, fetchUserProfile, login } from '../redux/ApiCalls';
import { useNavigate } from 'react-router-dom';
import storeConstants from '../redux/constans/actionTypes';

const CreateUser = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const selectedScope = useSelector((state) => state.scopeReducers.selectedScope);
    const inputValueLocation = useSelector((state) => state.locationReducer.inputValueLocation);
    const selectedTopic = useSelector((state) => state.topic.selectedTopic);
    const { campaignTitle } = useSelector((state) => state.campaignReducer);
    const { customText, templateText, formData: formDataMailto } = useSelector((state) => state.campaignReducer);
    const uploadedFile = useSelector((state) => state.campaignReducer.uploadedFiles);
    const { first_name, last_name } = useSelector((state) => state.users);

    const [formData, setFormData] = useState({
        username: '',  
        first_name: '',
        last_name: '',
        email: '',
        password: '',
        password2: ''
    });

    const [error, setError] = useState('');
    const [success, setSuccess] = useState('');

    useEffect(() => {
        if (first_name) {
            setFormData(prevFormData => ({
                ...prevFormData,
                first_name: first_name,
                last_name: last_name || '', 
            }));
        }
    }, [first_name, last_name]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        const sanitizedValue = name === 'username' || name === 'first_name' || name === 'last_name' ? value.replace(/\s+/g, '') : value;
        setFormData({
            ...formData,
            [name]: sanitizedValue,
        });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        setError('');
        setSuccess('');

        if (formData.password !== formData.password2) {
            setError('Passwords do not match.');
            return;
        }

        createUser({
            username: formData.username,
            email: formData.email,
            first_name: formData.first_name,
            last_name: formData.last_name,
            password: formData.password,
            password2: formData.password2
        })
            .then(userResponse => {
                return login({
                    email: formData.email,
                    password: formData.password
                })
                    .then(loginResponse => {
                        const token = loginResponse.data.access;
                        const formData = new FormData();
                        formData.append('scope', selectedScope);
                        formData.append('location', inputValueLocation);
                        formData.append('topic', selectedTopic);

                        if (campaignTitle) {
                            formData.append('title', campaignTitle);
                        }
                        formData.append('custom_text', customText);
                        formData.append('template_text', templateText);
                        formData.append('phone_number', formDataMailto.phone_number);
                        formData.append('email', formDataMailto.email);
                        formData.append('cc', formDataMailto.cc);
                        formData.append('bcc', formDataMailto.bcc);
                        formData.append('subject', formDataMailto.subject);
                        formData.append('message', formDataMailto.message);


                        if (uploadedFile && uploadedFile.length > 0) {
                            uploadedFile.forEach((file) => {
                              formData.append('uploaded_media', file);
                            });
                          }
                        const createCampaignPromise = (campaignTitle && token) ? createCampaign(formData, token) : Promise.resolve();

                        return createCampaignPromise
                            .then(() => {
                                return fetchUserProfile(token)
                                    .then(response => {
                                        const userId = response.data.user_id;
                                        dispatch({
                                            type: storeConstants.SET_USER_ID,
                                            payload: userId,
                                        });
                                        dispatch({
                                            type: storeConstants.SET_TOKEN,
                                            payload: token,
                                        });
                                        setSuccess('User and campaign (if applicable) created successfully!');
                                        navigate(`/user/${userId}/`);
                                    })
                                    .catch(error => {
                                        setError('Error fetching user profile: ' + (error.response?.data?.detail || error.message));
                                    });
                            })
                            .catch(error => {
                                setError('Error creating campaign: ' + (error.response?.data?.detail || error.message));
                            });
                    });
            })
            .catch(error => {
                setError('Error creating user or logging in: ' + (error.response?.data?.detail || error.message));
            });
    };

    return (
        <div className="max-w-xs  mx-auto px-4  px-4 pb-8  mt-32   shadow-lg rounded-lg">
            <h1 className="text-2xl font-semibold text-center">Kayıt Ol</h1>
            <form onSubmit={handleSubmit} className="mt-5">
                <input
                    type="text"
                    name="username"
                    value={formData.username}
                    onChange={handleChange}
                    placeholder="Kullanıcı Adı"
                    className="w-full mt-2 px-4 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                    required
                />
                <input
                    type="text"
                    name="first_name"
                    value={formData.first_name}
                    onChange={handleChange}
                    placeholder="Ad"
                    className="w-full mt-2 px-4 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                />
                <input
                    type="text"
                    name="last_name"
                    value={formData.last_name}
                    onChange={handleChange}
                    placeholder="Soyad"
                    className="w-full mt-2 px-4 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                />
                <input
                    type="email"
                    name="email"
                    value={formData.email}
                    onChange={handleChange}
                    placeholder="E-Posta"
                    className="w-full mt-2 px-4 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                    required
                />
                <input
                    type="password"
                    name="password"
                    value={formData.password}
                    onChange={handleChange}
                    placeholder="Şifre"
                    className="w-full mt-2 px-4 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                    required
                />
                <input
                    type="password"
                    name="password2"
                    value={formData.password2}
                    onChange={handleChange}
                    placeholder="Şifre Tekrar"
                    className="w-full mt-2 px-4 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                    required
                />
                <button
                    type="submit"
                    className="w-full bg-blue-500 hover:bg-blue-600 text-white font-semibold py-2 rounded-md mt-2"
                >
                    Kullanıcı Oluştur
                </button>
                <p className="text-center mt-2">
                    <a href="/login" className="text-blue-500 hover:underline">Bir hesabınız yok mu? Giriş</a>
                </p>
            </form>
            {error && <p className="text-red-500 text-center mt-2">{error}</p>}
            {success && <p className="text-green-500 text-center mt-2">{success}</p>}
        </div>
    );
};

export default CreateUser;
