import { createStore, applyMiddleware, compose } from 'redux';
import {thunk} from 'redux-thunk'; // Import thunk correctly
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import rootReducer from './reducers';  // Import your root reducer

const persistConfig = {
  key: 'persisted',
  storage,
  debug: true,
  whitelist: ["users", "campaignReducer"], // Adjust your whitelist if necessary
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

const initialState = {};

const middleware = [thunk];

// Configure Redux DevTools
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const configureStore = createStore(
  persistedReducer,
  initialState,
  composeEnhancers(
    applyMiddleware(...middleware)
  )
);

const persistor = persistStore(configureStore);

export { configureStore, persistor };
