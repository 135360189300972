const StepNavigation = ({ steps, activeStep ,darkMode}) => {
  return (
    <div className="w-full  mt-8">
      <div className="flex items-center justify-between gap-3  bg-white rounded w-full">
        <div className="flex items-center w-full">
            <div
              className="bg-blue-500 h-2 rounded-3xl"
              style={{ width: `${((activeStep + 1) / steps.length) * 100}%` }}
            ></div>
        </div>
      </div>
    </div>
  );
};

export default StepNavigation;

