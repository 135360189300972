
import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import storeConstants from '../redux/constans/actionTypes';
import LocationOnIcon from '@mui/icons-material/LocationOn';

const LocationAutocomplete = ({ darkMode }) => {
  const dispatch = useDispatch();
  const { inputValueLocation, options = [] } = useSelector((state) => state.locationReducer);
  const [showOptions, setShowOptions] = useState(false);

  useEffect(() => {
    if (inputValueLocation === '') {
      dispatch({ type: storeConstants.SET_OPTIONS, payload: [] });
      return;
    }

    fetch(`https://nominatim.openstreetmap.org/search?format=json&q=${inputValueLocation}&addressdetails=1`)
      .then(response => response.json())
      .then(data => {
        dispatch({
          type: storeConstants.SET_OPTIONS,
          payload: data.map(item => ({
            description: item.display_name,
            lat: item.lat,
            lon: item.lon,
            address: item.address,
            place_id: item.place_id,
          })),
        });
        setShowOptions(true);
      });
  }, [inputValueLocation, dispatch]);

  const handleOptionSelect = (option) => {
    dispatch({ type: storeConstants.SET_SELECTED_LOCATION, payload: option });
    dispatch({ type: storeConstants.SET_INPUT_VALUE_LOCATION, payload: option.description });
    dispatch({ type: storeConstants.SET_OPTIONS, payload: [] });
    setShowOptions(false);
  };

  return (
    <div className={`flex flex-col items-center w-full ${darkMode ? 'text-white' : 'text-black'}`}>
      {/* <div className="flex flex-col sm:flex-row gap-4 w-full">
        <h4 className="text-2xl font-bold">Konumunuzu Arayın</h4>
        <p className="text-lg sm:text-xl mt-4">Konumları bulmak için bir mahalle, şehir, eyalet veya bölge girin.</p>
      </div> */}
         <div className="flex flex-col sm:flex-row gap-4 w-full">
        <div className="w-full  sm:text-start">
          <h2 className="text-2xl font-bold">
          Konumunuzu Arayın
          </h2>
          <p className="text-lg sm:text-xl ">Konumları bulmak için bir mahalle, şehir, eyalet veya bölge girin.</p>
        </div>
      </div>
      <div className="flex flex-col sm:flex-row gap-4 w-full mt-4">

        <input
          type="text"
          className={`w-full p-2 border rounded-md focus:outline-none focus:ring-2 ${darkMode ? 'border-gray-200 bg-gray-200 text-white focus:ring-blue-500' : 'border-gray-300 bg-white text-black focus:ring-blue-500'}`}
          placeholder="Konum ara"
          value={inputValueLocation}
          onChange={(e) => {
            const newValue = e.target.value;
            dispatch({ type: storeConstants.SET_INPUT_VALUE_LOCATION, payload: newValue });
            setShowOptions(true);
          }}
          onFocus={() => inputValueLocation && options.length > 0 && setShowOptions(true)}
        />
        {showOptions && options.length > 0 && (
          <ul className={`absolute z-10 w-full mt-1 rounded-md shadow-lg max-h-60 overflow-auto ${darkMode ? 'bg-gray-800' : 'bg-white'} border border-gray-300`}>
            {options.map((option) => (
              <li
                key={option.place_id}
                className={`flex items-center p-2 hover:bg-blue-500 hover:text-white cursor-pointer ${darkMode ? 'text-gray-300' : 'text-gray-600'}`}
                onClick={() => handleOptionSelect(option)}
              >
                <span className="mr-2">
                  <LocationOnIcon />
                </span>
                <div>
                  <p className="font-medium">{option.description}</p>
                  <p className="text-gray-500">{option.address.city || option.address.state}</p>
                </div>
              </li>
            ))}
          </ul>
        )}
      </div>

     
    </div>
  );
};

export default LocationAutocomplete;
