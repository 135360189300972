import storeConstants from "../constans/actionTypes";

const initialState = {
    inputValueLocation: '',
    options: [],
    selectedLocation: null,
    localDetail: '',
  };
  


const locationReducer = (state = initialState, action) => {
    switch (action.type) {
      case storeConstants.SET_INPUT_VALUE_LOCATION:
        return { ...state, inputValueLocation: action.payload };
      case storeConstants.SET_OPTIONS:
        return { ...state, options: action.payload };
      case storeConstants.SET_SELECTED_LOCATION:
        return { ...state, selectedLocation: action.payload };
    case storeConstants.SET_LOCAL_DETAIL: // Yeni action case
        return { ...state, localDetail: action.payload };
    case storeConstants.RESET_CAMPAIGN_STATE:
        return initialState;
      default:
        return state;
    }
  };
  

export default locationReducer;