import storeConstants from "../constans/actionTypes";


const initialState = {
    selectedScope: null,
  };

const scopeReducers = (state = initialState, action) => {
    switch (action.type) {
      case storeConstants.SELECT_SCOPE:
        return {
          ...state,
          selectedScope: action.payload,
        };
      case storeConstants.RESET_CAMPAIGN_STATE:
        return initialState;
      default:
        return state;
    }
  };

  export default scopeReducers;