
import React, { useState } from 'react';
import { Button, Box } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { deleteCampaign } from '../redux/ApiCalls';
import { useDispatch, useSelector } from 'react-redux';
import ConfirmationDialog from './ConfirmationDialog ';
import { useNavigate } from 'react-router-dom';
import storeConstants from '../redux/constans/actionTypes';

const CampaignEditAndDeleteButton = ({ campaign, onEditClick = () => { }, onDeleteClick = () => { } }) => {
  const [openDialog, setOpenDialog] = useState(false);
  const token = useSelector((state) => state?.users?.token);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleDeleteClick = () => {
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };




  const handleConfirmDelete = async () => {
    console.log(campaign.id, "campaign.id")
    try {
      await deleteCampaign(campaign.id, token);
      console.log('Campaign deleted successfully');
      navigate("/")
    } catch (error) {
      console.error('Error deleting campaign:', error);
    } finally {
      handleCloseDialog();
    }
  };



  const handleEdit = (campaign) => {
    dispatch({
      type: storeConstants.SET_CAMPAIGN_ID,
      payload: { campaignId: campaign.id },
    });
    dispatch({
      type: storeConstants.SET_INPUT_VALUE_LOCATION,
      payload: campaign.location,
    });
    dispatch({
      type: storeConstants.SELECT_SCOPE,
      payload: campaign.scope,
    });
    dispatch({
      type: storeConstants.SET_CAMPAIGN_TEXT,
      payload: { templateText: campaign.content, campaignTitle: campaign.title },
    });
    dispatch({
      type: storeConstants.SET_SELECTED_TOPIC,
      payload: campaign.topic,
    });
    dispatch({
      type: storeConstants.SET_UPLOADED_FILE,
      payload: campaign.media || null,
    });
    const formData = {
      phone_number: campaign.phone_number || '',
      email: campaign.email || '',
      cc: campaign.cc || '',
      bcc: campaign.bcc || '',
      subject: campaign.subject || '',
      message: campaign.message || '',
      button_name: campaign.button_name || '',
    };

    Object.keys(formData).forEach(key => {
      dispatch({
        type: storeConstants.SET_CAMPAIGN_FORM_DATA,
        payload: { [key]: formData[key] },
      });
    });
    navigate(`/edit-campaign/${campaign.id}`);
  };

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
        }}
        className="p-1"
      >
        <Button
          onClick={() => {
            handleEdit(campaign);
          }}
          variant="outlined"
          color="primary"
          size="small"
          startIcon={<EditIcon />}
          sx={{
            '& > span': {
              margin: 0,
            },
            fontSize: {
              xxs: '0.65rem',
              xs: '0.75rem', // Extra-small screens
              sm: '0.85rem', // Small screens
              md: '1rem',    // Medium screens and up
            },
          }}
        >
          Düzenle
        </Button>
        <Button
          onClick={handleDeleteClick}
          variant="outlined"
          color="error"
          size="small"
          startIcon={<DeleteIcon />}
          sx={{
            '& > span': {
              margin: 0,
            },
            fontSize: {
              xxs: '0.65rem',
              xs: '0.75rem', // Extra-small screens
              sm: '0.85rem', // Small screens
              md: '1rem',    // Medium screens and up
            },
          }}
        >
          Sil
        </Button>
      </Box>

      <ConfirmationDialog
        open={openDialog}
        onClose={handleCloseDialog}
        onConfirm={handleConfirmDelete}
      />
    </>
  );
};

export default CampaignEditAndDeleteButton;
