

// import React, { useState } from 'react';
// import { TextField, Button, Container, Typography, Paper, Link } from '@mui/material';
// import { useDispatch } from 'react-redux';
// import axios from 'axios';
// import { useNavigate } from 'react-router-dom';
// import storeConstants from '../redux/constans/actionTypes';
// import { fetchUserProfile } from '../redux/ApiCalls';

// const Login = () => {
//     const [email, setEmail] = useState('');
//     const [password, setPassword] = useState('');
//     const [error, setError] = useState('');
//     const dispatch = useDispatch();
//     const navigate = useNavigate();

//     const handleSubmit = (e) => {
//         e.preventDefault();
//         setError('');
    
//         axios.post(`${process.env.REACT_APP_REST_URL}users/login/`, {
//             email: email,
//             password,
//         })
//         .then(response => {
//             const { access } = response.data;
            
//             fetchUserProfile(access)
//             .then(profileResponse => {
//                 const userId = profileResponse.data.user_id;
//                 dispatch({
//                     type: storeConstants.SET_USER_ID,
//                     payload: userId,
//                 });
//                 dispatch({
//                     type: storeConstants.SET_TOKEN,
//                     payload: access,
//                 });
//                 navigate(`/user/${userId}/`);
//             })
//             .catch(profileError => {
//                 setError('Kullanıcı profili alınırken hata oluştu: ' + (profileError.response?.data?.detail || profileError.message));
//             });
//         })
//         .catch(error => {
//             setError('Geçersiz e-posta veya şifre.');
//         });
//     };

//     return (
//         <Container component="main" maxWidth="xs">
//             <Paper elevation={3} style={{ padding: '20px', marginTop: '20px' }}>
//                 <Typography variant="h5" component="h1" align="center">
//                     Giriş
//                 </Typography>
//                 <form onSubmit={handleSubmit} style={{ marginTop: '20px' }}>
//                     <TextField
//                         label="E-Posta"
//                         type="email"
//                         fullWidth
//                         required
//                         variant="outlined"
//                         value={email}
//                         onChange={(e) => setEmail(e.target.value)}
//                         margin="normal"
//                     />
//                     <TextField
//                         label="Şifre"
//                         type="password"
//                         fullWidth
//                         required
//                         variant="outlined"
//                         value={password}
//                         onChange={(e) => setPassword(e.target.value)}
//                         margin="normal"
//                     />
//                     <Button
//                         type="submit"
//                         variant="contained"
//                         color="primary"
//                         fullWidth
//                         style={{ marginTop: '20px' }}
//                     >
//                         GİRİŞ
//                     </Button>
//                 </form>
//                 {error && (
//                     <Typography color="error" align="center" style={{ marginTop: '10px' }}>
//                         {error}
//                     </Typography>
//                 )}
//                 <Typography align="center" style={{ marginTop: '10px' }}>
//                     <Link href="/register" variant="body2">
//                         Hesabım yok? Kayıt Ol
//                     </Link>
//                 </Typography>
//             </Paper>
//         </Container>
//     );
// };

// export default Login;


import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import storeConstants from '../redux/constans/actionTypes';
import { fetchUserProfile } from '../redux/ApiCalls';

const Login = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const handleSubmit = (e) => {
        e.preventDefault();
        setError('');

        axios.post(`${process.env.REACT_APP_REST_URL}users/login/`, {
            email: email,
            password,
        })
        .then(response => {
            const { access } = response.data;
            
            fetchUserProfile(access)
            .then(profileResponse => {
                const userId = profileResponse.data.user_id;
                dispatch({
                    type: storeConstants.SET_USER_ID,
                    payload: userId,
                });
                dispatch({
                    type: storeConstants.SET_TOKEN,
                    payload: access,
                });
                navigate(`/user/${userId}/`);
            })
            .catch(profileError => {
                setError('Kullanıcı profili alınırken hata oluştu: ' + (profileError.response?.data?.detail || profileError.message));
            });
        })
        .catch(() => {
            setError('Geçersiz e-posta veya şifre.');
        });
    };

    return (
        <div className="max-w-xs mx-auto px-4 pb-8  mt-32  shadow-lg rounded-lg">
            <h1 className="text-2xl font-semibold text-center">Giriş</h1>
            <form onSubmit={handleSubmit} className="mt-5">
                <input
                    type="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    placeholder="E-Posta"
                    className="w-full mt-4 px-4 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                    required
                />
                <input
                    type="password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    placeholder="Şifre"
                    className="w-full mt-4 px-4 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                    required
                />
                <button
                    type="submit"
                    className="w-full bg-blue-500 hover:bg-blue-600 text-white font-semibold py-2 rounded-md mt-4"
                >
                    GİRİŞ
                </button>
            </form>
            {error && (
                <p className="text-red-500 text-center mt-4">{error}</p>
            )}
            <p className="text-center mt-4">
                <a href="/register" className="text-blue-500 hover:underline">Hesabım yok? Kayıt Ol</a>
            </p>
        </div>
    );
};

export default Login;
