import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import storeConstants from '../redux/constans/actionTypes';

const ContactForm = ({ darkMode }) => {
    const dispatch = useDispatch();
    const formData = useSelector(state => state.campaignReducer.formData);
    const { textAi, campaignTitleAi } = useSelector(state => state.campaignReducer);
    const handleChange = (e) => {
        const { name, value } = e.target;

        if (name === 'phone_number') {
            const numericValue = value.replace(/[^0-9]/g, ''); 
            dispatch({
                type: storeConstants.SET_CAMPAIGN_FORM_DATA,
                payload: { [name]: numericValue },
            });
        } else {
            dispatch({
                type: storeConstants.SET_CAMPAIGN_FORM_DATA,
                payload: { [name]: value },
            });
        }
    };

    return (

     

        <form className={`max-w-lg w-full mx-auto flex flex-col mt-5`}>
        <h2 className="text-xl font-bold text-start">Aranmasını İstediğiniz Telefon</h2>

            <div className="mb-2">
                <input
                    type="tel"
                    name="phone_number"
                    id="phone_number"
                    placeholder="Telefon Numarası"
                    value={formData.phone_number || ''}
                    onChange={handleChange}
                    className={`mt-1 block w-full border ${darkMode ? 'border-gray-600 bg-gray-700' : 'border-gray-300'} rounded-md p-2 focus:outline-none focus:ring-2 focus:ring-blue-500`}
                    inputMode="numeric"
                    pattern="[0-9]*"
                />
            </div>
            <h2 className="text-xl font-bold text-start ">Gönderilmesini İstediğiniz Email</h2>

            <div className="mb-2">
                <input
                    type="email"
                    name="email"
                    id="email"
                    placeholder="Alıcı E-Posta Adresi"
                    value={formData.email || ''}
                    onChange={handleChange}
                    className={`mt-1 block w-full border ${darkMode ? 'border-gray-600 bg-gray-700' : 'border-gray-300'} rounded-md p-2 focus:outline-none focus:ring-2 focus:ring-blue-500`}
                />
            </div>

            <div className="mb-2">
                <input
                    type="text"
                    name="cc"
                    id="cc"
                    placeholder="CC E-Posta Adresi"
                    value={formData.cc || ''}
                    onChange={handleChange}
                    className={`mt-1 block w-full border ${darkMode ? 'border-gray-600 bg-gray-700' : 'border-gray-300'} rounded-md p-2 focus:outline-none focus:ring-2 focus:ring-blue-500`}
                />
            </div>

            <div className="mb-2">
                <input
                    type="text"
                    name="bcc"
                    id="bcc"
                    placeholder="BCC E-Posta Adresi"
                    value={formData.bcc || ''}
                    onChange={handleChange}
                    className={`mt-1 block w-full border ${darkMode ? 'border-gray-600 bg-gray-700' : 'border-gray-300'} rounded-md p-2 focus:outline-none focus:ring-2 focus:ring-blue-500`}
                />
            </div>

            <div className="mb-2">
                <input
                    type="text"
                    name="subject"
                    id="subject"
                    placeholder="E-Posta Konusu"
                    value={formData.subject || campaignTitleAi || ''}
                    onChange={handleChange}
                    className={`mt-1 block w-full border ${darkMode ? 'border-gray-600 bg-gray-700' : 'border-gray-300'} rounded-md p-2 focus:outline-none focus:ring-2 focus:ring-blue-500`}
                />
            </div>

            <div className="mb-2">
                <textarea
                    name="message"
                    id="message"
                    placeholder="Mesaj"
                    value={formData.message || textAi || ''}
                    onChange={handleChange}
                    className={`mt-1 block w-full border ${darkMode ? 'border-gray-600 bg-gray-700' : 'border-gray-300'} rounded-md p-2 focus:outline-none focus:ring-2 focus:ring-blue-500`}
                    rows="4" // Adjusted number of rows for textarea
                />
            </div>
        </form>
    );
};

export default ContactForm;
