

// import React, { useEffect, useState } from 'react';
// import { Link } from 'react-router-dom';
// import { useSelector, useDispatch } from 'react-redux';
// import { jwtDecode } from 'jwt-decode';
// import storeConstants from '../redux/constans/actionTypes';
// import {
//   AppBar,
//   Toolbar,
//   IconButton,
//   Typography,
//   InputBase,
//   Menu,
//   MenuItem,
//   Badge,
// } from '@mui/material';
// import MenuIcon from '@mui/icons-material/Menu';
// import SearchIcon from '@mui/icons-material/Search';
// import AccountCircle from '@mui/icons-material/AccountCircle';
// import CampaignIcon from '@mui/icons-material/Campaign';
// import LoginIcon from '@mui/icons-material/Login';
// import ExitToAppIcon from '@mui/icons-material/ExitToApp';
// import FlagsSelect from 'react-flags-select';
// import { useIntl } from 'react-intl';

// const Navbar = ({ darkMode, toggleDarkMode }) => {
//   const [anchorEl, setAnchorEl] = useState(null);
//   const [selected, setSelected] = useState('US'); // Varsayılan dil
//   const token = useSelector((state) => state?.users?.token);
//   const userId = useSelector((state) => state?.users?.userId);
//   const dispatch = useDispatch();
//   const intl = useIntl();

//   useEffect(() => {
//     const isTokenExpired = (token) => {
//       if (!token) return true;
//       try {
//         const decodedToken = jwtDecode(token);
//         const currentTime = Date.now() / 1000;
//         return decodedToken.exp < currentTime;
//       } catch (e) {
//         return true;
//       }
//     };

//     if (isTokenExpired(token)) {
//       handleLogout();
//     }
//   }, [token, dispatch]);

//   const handleLogout = () => {
//     dispatch({ type: storeConstants.RESET_TOKEN, payload: null });
//   };

//   const handleProfileMenuOpen = (event) => {
//     setAnchorEl(event.currentTarget);
//   };

//   const handleMenuClose = () => {
//     setAnchorEl(null);
//   };

//   const renderMobileMenu = (
//     <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleMenuClose}>
//       <MenuItem component={Link} to="campaign">
//         <Badge>
//           <CampaignIcon />
//         </Badge>
//         <Typography>{intl.formatMessage({ id: 'report_injustice' })}</Typography>
//       </MenuItem>
//       {token && (
//         <MenuItem component={Link} to={`/user/${userId}`}>
//           <AccountCircle />
//           <Typography>{intl.formatMessage({ id: 'my_profile' })}</Typography>
//         </MenuItem>
//       )}
//       {token ? (
//         <MenuItem onClick={handleLogout}>
//           <ExitToAppIcon />
//           <Typography>{intl.formatMessage({ id: 'logout' })}</Typography>
//         </MenuItem>
//       ) : (
//         <MenuItem component={Link} to="login">
//           <LoginIcon />
//           <Typography>{intl.formatMessage({ id: 'login' })}</Typography>
//         </MenuItem>
//       )}
//     </Menu>
//   );

//   return (
//     <AppBar position="fixed" className={`bg-${darkMode ? 'dark' : 'light'}`}>
//       <Toolbar className="flex justify-between items-center">
//         <Link to="/" className="text-lg font-bold">JointVoice</Link>
//         <div className="relative flex-grow mx-2">
//           <SearchIcon className="absolute left-2 top-1/2 transform -translate-y-1/2" />
//           <InputBase
//             placeholder={intl.formatMessage({ id: 'search_placeholder' })}
//             className="pl-10 pr-4 py-2 rounded w-full"
//           />
//         </div>
        // <FlagsSelect
        //   selected={selected}
        //   onSelect={(code) => setSelected(code)}
        //   countries={["US", "TR"]}
        //   customLabels={{ US: "EN-US", GB: "EN-GB", FR: "FR", DE: "DE", IT: "IT" }}
        //   placeholder="Select Language"
        //    className="bg-[#1976d2] text-black"
        // />
//         <IconButton onClick={handleProfileMenuOpen}>
//           <MenuIcon />
//         </IconButton>
//       </Toolbar>
//       {renderMobileMenu}
//     </AppBar>
//   );
// };

// export default Navbar;






import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { jwtDecode } from 'jwt-decode';
import storeConstants from '../redux/constans/actionTypes';
import {
  IconButton,
  Badge,
  Typography,
  AppBar,
  Toolbar,
  InputBase,
  Menu,
  MenuItem,
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import SearchIcon from '@mui/icons-material/Search';
import AccountCircle from '@mui/icons-material/AccountCircle';
import CampaignIcon from '@mui/icons-material/Campaign';
import LoginIcon from '@mui/icons-material/Login';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import DarkModeIcon from '@mui/icons-material/DarkMode';
import FlagsSelect from 'react-flags-select';
import { useIntl } from 'react-intl';

const Navbar = ({ darkMode, toggleDarkMode }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [isDesktop, setIsDesktop] = useState(window.innerWidth >= 768); 
  const token = useSelector((state) => state?.users?.token);
  const userId = useSelector((state) => state?.users?.userId);
  const dispatch = useDispatch();
  const intl = useIntl();


  useEffect(() => {
    const isTokenExpired = (token) => {
      if (!token) return true;
      try {
        const decodedToken = jwtDecode(token);
        const currentTime = Date.now() / 1000;
        return decodedToken.exp < currentTime;
      } catch (e) {
        return true;
      }
    };

    if (isTokenExpired(token)) {
      handleLogout();
    }
  }, [token, dispatch]);

  const handleLogout = () => {
    dispatch({ type: storeConstants.RESET_TOKEN, payload: null });
  };

  const handleProfileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const renderMobileMenu = (
    <Menu
      anchorEl={anchorEl}
      open={Boolean(anchorEl)}
      onClose={handleMenuClose}
    >
      <MenuItem component={Link} to="campaign">
        <Badge>
          <CampaignIcon />
        </Badge>
        <Typography>{intl.formatMessage({ id: 'report_injustice' })}</Typography>
      </MenuItem>
      {token && (
        <MenuItem component={Link} to={`/user/${userId}`}>
          <AccountCircle />
          <Typography>{intl.formatMessage({ id: 'my_profile' })}</Typography>
        </MenuItem>
      )}
      {token ? (
        <MenuItem onClick={handleLogout}>
          <ExitToAppIcon />
          <Typography>{intl.formatMessage({ id: 'logout' })}</Typography>
        </MenuItem>
      ) : (
        <MenuItem component={Link} to="login">
          <LoginIcon />
          <Typography>{intl.formatMessage({ id: 'login' })}</Typography>
        </MenuItem>
        
      )}
      
    </Menu>
  );

  useEffect(() => {
    const handleResize = () => {
      setIsDesktop(window.innerWidth >= 768); 
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const handleButtonClick = () => {
    dispatch({
      type: storeConstants.RESET_CAMPAIGN_STATE,
    });
  };




  return (
    <AppBar
  style={{ paddingRight: "0px", paddingLeft: "0px", zIndex: 50 }} // AppBar için z-index ayarlandı
  position="fixed"
  className={`mt-10 text-white bg-${darkMode ? '[#ca113d]' : 'blue-600'} shadow-none transition`}
>
  <Toolbar
    className={`flex w-11/12 sm:w-11/12 md:w-4/5 lg:w-3/4 mx-auto justify-between items-center p-0 ${darkMode ? 'text-white' : 'text-white'}`}
    style={{ paddingRight: "0px", paddingLeft: "0px", zIndex: 50 }} // Toolbar için z-index ayarlandı
  >
        <Link to="/" className="text-lg font-bold">
          JointVoice
        </Link>
        <div className="relative flex-grow mx-2 sm:mx-4 md:mx-6 max-w-xs sm:max-w-sm md:max-w-md">
          <SearchIcon className="absolute left-2 top-1/2 transform -translate-y-1/2" />
          <InputBase
                       placeholder={intl.formatMessage({ id: 'search_placeholder' })}

            className={`bg-transparent pl-2 pr-4 py-2 rounded w-full bg-white ${darkMode ? 'text-gray-600 border-b border-gray-300 ' : 'text-black border-b border-gray-300'}`}
          />
        </div>
        <div className="hidden md:flex w-1/3 justify-around">
  <Typography 
    className="text-sm cursor-pointer"
    component={Link} 
    to="campaign"
    onClick={handleButtonClick}
  >
    <Badge>{intl.formatMessage({ id: 'report_injustice' })}</Badge>
  </Typography>

  {token ? (
    <>
      <Typography
        className="text-sm cursor-pointer"
        aria-label="account of current user"
        aria-haspopup="true"
        onClick={handleProfileMenuOpen}
      >
        {intl.formatMessage({ id: 'my_profile' })}
      </Typography>
      <Typography
        className="text-sm cursor-pointer"
        onClick={handleLogout}
      >
        <Badge>{intl.formatMessage({ id: 'logout' })}</Badge>
      </Typography>
    </>
  ) : (
    <Typography
      className="text-sm cursor-pointer"
      component={Link} 
      to="login"
    >
      <Badge>{intl.formatMessage({ id: 'login' })}</Badge>
    </Typography>
  )}
</div>

        <div className="flex items-center">
          {!isDesktop && (
            <IconButton onClick={handleProfileMenuOpen} className={`${darkMode ? ' text-white' : 'text-white'}`}>
            <MenuIcon />
          </IconButton>  
            )}
       
        </div>
        
      </Toolbar>
      {renderMobileMenu}
    </AppBar>
  );
};

export default Navbar;

