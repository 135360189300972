import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import storeConstants from '../redux/constans/actionTypes';

const Scope = ({ darkMode }) => {
  const selectedScope = useSelector((state) => state.scopeReducers.selectedScope);

  const dispatch = useDispatch();

  const handleButtonClickDispatch = (scope) => {
    dispatch({
      type: storeConstants.SELECT_SCOPE,
      payload: scope,
    });
  };
  const getCardStyles = (scope) => {
    const isSelected = selectedScope === scope;
    return `
      w-full p-4 py-2.5 text-sm rounded-lg cursor-pointer font-semibold text-center shadow-xs transition-all duration-300 
      ${isSelected ? 'bg-blue-600 text-white' : (darkMode ? 'bg-gray-200 text-gray-800' : 'bg-gray-200 text-gray-800')} 
      ${isSelected ? 'hover:bg-blue-700' : (darkMode ? 'hover:bg-gray-600' : 'hover:bg-gray-300')}
    `;
  };

  return (
    <div className={`flex flex-col items-center w-full`}>
      <div className="flex flex-col sm:flex-row gap-4 w-full">
        <div className="w-full sm:text-start">
          <h2 className="text-2xl font-bold">
            Değişim için ilk adımı birlikte atalım
          </h2>
          <p className="text-lg sm:text-xl mt-4">Duyuru Kampsamını Seç:</p>
        </div>
      </div>

      <div className="flex flex-col sm:flex-row gap-4 w-full mt-4">
        {['Yerel', 'Ulusal', 'Küresel'].map(scope => (
          <div 
            key={scope}
            className={getCardStyles(scope)}
            role="alert" 
            onClick={() => handleButtonClickDispatch(scope)}
          >
            <p className="text-xl text-center sm:text-2xl font-medium">{scope}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Scope;
