import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { fetchCampaignById, trackClick } from '../redux/ApiCalls';
import CampaignCard from '../components/CampaignCard';
import { useDispatch, useSelector } from 'react-redux';
import storeConstants from '../redux/constans/actionTypes';
import UserInfoModal from '../components/UserInfoModal';
import demoImg from '../assets/images/demo.jpg';
import axios from 'axios';
import {Helmet} from 'react-helmet-async';

const CampaignDetail = ({ darkMode }) => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [campaign, setCampaign] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const token = useSelector((state) => state?.users?.token);
  const dispatch = useDispatch();
  const [selectedCampaign, setSelectedCampaign] = useState(null);
  const baseUrl = process.env.REACT_APP_REST_URL;

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetchCampaignById(id);
        setCampaign(response.data);
        setError('');
      } catch (error) {
        setError('Site Güncelleniyor');
      } finally {
        setLoading(false);
      }
    };

    if (id) {
      fetchData();
    } else {
      setError('No campaign ID provided');
      setLoading(false);
    }
  }, [id]);

  const handleEmailClick = (campaign) => {
    setSelectedCampaign(campaign);
    dispatch({
      type: storeConstants.SET_USER_INFO_MODAL_OPEN,
      payload: true,
    });
  };

  const handleConfirm = ({ first_name, last_name }) => {
    trackClick(selectedCampaign.id)
      .then(() => {
        const emailLink = `mailto:${selectedCampaign.email}?cc=${selectedCampaign.cc || ''}&bcc=${selectedCampaign.bcc || ''}&subject=${encodeURIComponent(selectedCampaign.subject || '')}&body=${encodeURIComponent(selectedCampaign.message || '')}%0A%0A ${first_name} ${last_name}`;
        window.location.href = emailLink;
        if (!token) {
          setTimeout(() => navigate('/register'), 1000);
        }
      })
      .catch((error) => console.error('Error tracking click:', error));
  };

  const handlePhoneCall = (phoneNumber) => {
    const sanitizedPhoneNumber = phoneNumber.replace(/[^\d]/g, '');
    if (sanitizedPhoneNumber) {
      window.location.href = `tel:${sanitizedPhoneNumber}`;
    } else {
      console.error("No valid phone number available");
    }
  };

  const handleShare = async (campaign) => {
    if (navigator.share) {
      const shareTitle = campaign.title || 'Başlık Yok';
      const shareUrl = `${window.location.origin}/campaigns/${campaign.id}`;
      const file = new File([await fetch(campaign.media[0].file)], 'filename.jpg', {
        type: 'image/jpeg'
      });
      navigator.share({
        files: [file],
        text: shareTitle,
        url: shareUrl,
      })
        .then(() => console.log('Paylaşım başarılı.'))
        .catch((error) => {
          console.error('Paylaşım hatası:', error);
          alert('Paylaşım sırasında bir hata oluştu.');
        });
    } else {
      const shareText = `${campaign.title || 'Başlık Yok'}\n${window.location.href}`;
      navigator.clipboard.writeText(shareText)
        .then(() => alert('Kampanya bağlantısı panoya kopyalandı!'))
        .catch((error) => {
          console.error('Panoya kopyalama hatası:', error);
          alert('Panoya kopyalama sırasında bir hata oluştu.');
        });
    }
  };
  
  const handleDetailUser = (userId) => navigate(`/user/${userId}`);

  if (loading) return <div className="flex justify-center py-4"><span className="loader" /></div>;
  if (error) return <p className="text-red-500">{error}</p>;

  if (!campaign){
    console.error('No campaign data found');
    return null;  // Don't render until data is available
  }
  
  return (
    <div className={`flex flex-col mt-20 pt-10 p-2 min-h-screen ${darkMode ? 'bg-[#091840]' : 'bg-white'}`}>
      <Helmet defer={false}>
        <title>{campaign.title}</title>
        <meta property="og:title" content={campaign.title}  data-rh="true"/>
        <meta property="og:description" content={campaign.content}  data-rh="true"/>
        <meta property="og:image" content={campaign.media[0].file}  data-rh="true"/>
        <meta property="og:url" content={window.location.href}  data-rh="true"/>
        <meta name="twitter:card" content="summary_large_image"  data-rh="true"/>
      </Helmet>
      <h2 className={`w-full  ${darkMode ? 'bg-[#ca113d]' : 'bg-[#091840]'} text-white text-1xl sm:text-2xl font-bold text-center py-2 px-2 mb-2 rounded-[5px]`}>
        KAMPANYA DETAYLARI
      </h2>
      <div className="flex-grow flex flex-col overflow-y-auto ">
        {campaign && (
          <CampaignCard
            campaign={campaign}
            onEmailClick={handleEmailClick}
            onPhoneCall={handlePhoneCall}
            onDetailUser={handleDetailUser}
            onBoxClick={() => {}}
            isDetailPage={true}
            onShare={handleShare}
            isDetailPageDeleteEdit={true}
            show={true}
          />
        )}
      </div>
      <UserInfoModal onConfirm={handleConfirm} />
    </div>
  );
};

export default CampaignDetail;
