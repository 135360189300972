import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import ContactForm from "./components/ContactForm";
import CreateUser from "./pages/CreateUser";
import Homepage from "./pages/Homepage";
import "./App.css";
import Scope from "./components/Scope";
import Navbar from "./components/Navbar";
import HorizontalLinearStepper from "./pages/HorizontalLinearStepper";
import PrivateRoute from "./components/PrivateRoute";
import Login from "./pages/Login";
import CampaignDetail from "./pages/CampaignDetail";
import AdminLink from "./pages/AdminLink";
import UserDetailAndCampaignDetail from "./pages/UserDetailAndCampaignDetail";
import WPBand from "./components/wpband/WPBand";
import NotificationWrapper from "./components/modal/notification-wrapper";
import { fetchCampaigns } from "./redux/ApiCalls";
import { useDispatch } from "react-redux";
import storeConstants from "./redux/constans/actionTypes";

import { HelmetProvider } from 'react-helmet-async';

const App = () => {
  const [darkMode, setDarkMode] = useState(false);
  const toggleDarkMode = () => {
    setDarkMode((prevMode) => !prevMode);
  };
  const [campaigns, setCampaigns] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");
  const dispatch = useDispatch();

  useEffect(() => {
    const loadCampaigns = async () => {
      try {
        const response = await fetchCampaigns();

        setCampaigns(response.data);
        setLoading(false);

        dispatch({
          type: storeConstants.SET_CAMPAIGNS,
          payload: response.data,
        });
      } catch (error) {
        setError("Bakım ve Güncelleme Yapılıyor");
        setLoading(false);
      }
    };

    loadCampaigns();
  }, [dispatch]);

  return (
    <HelmetProvider>
      <Router>
        <div
          className={`${
            darkMode ? "bg-black text-white" : "bg-white text-gray-900"
          } min-h-screen flex flex-col`}
        >
          <NotificationWrapper />
          <WPBand darkMode={darkMode} />
          <Navbar
            darkMode={darkMode}
            toggleDarkMode={toggleDarkMode}
            campaigns={campaigns}
            setCampaigns={setCampaigns}
            loading={loading}
            setLoading={setLoading}
          />
          <div className="flex-grow">
            <Routes>
              <Route
                path="/"
                element={
                  <Homepage
                    darkMode={darkMode}
                    campaigns={campaigns}
                    setCampaigns={setCampaigns}
                    loading={loading}
                    setLoading={setLoading}
                  />
                }
              />
              <Route path="/login" element={<Login darkMode={darkMode} />} />
              <Route
                path="/api/mail/contact"
                element={<ContactForm darkMode={darkMode} />}
              />
              <Route
                path="/api/users/create"
                element={<CreateUser darkMode={darkMode} />}
              />
              <Route
                path="register"
                element={<CreateUser darkMode={darkMode} />}
              />
              <Route
                path="/campaign"
                element={<HorizontalLinearStepper darkMode={darkMode} />}
              />
              <Route path="/admin" element={<AdminLink darkMode={darkMode} />} />
              <Route
                path="/edit-campaign/:id"
                element={<HorizontalLinearStepper darkMode={darkMode} />}
              />
              <Route
                path="/campaigns/:id"
                element={<CampaignDetail darkMode={darkMode} />}
              />
              <Route
                path="/user/:id"
                element={<UserDetailAndCampaignDetail darkMode={darkMode} />}
              />
              <Route
                path="/scope"
                element={<PrivateRoute element={Scope} darkMode={darkMode} />}
              />
            </Routes>
          </div>
        </div>
      </Router>
    </HelmetProvider>
  );
};

export default App;
