
import storeConstants from "../constans/actionTypes";

const initialState = {
  modalOpen: false,
  first_name: '',
  last_name: '',
  username:'',
  confirmModal: false,
  loading: false,
  users: [],
  error: null,
  token: null,
  userId: null,
  createUserSuccess: false,
  user:{
    first_name: '',
    last_name: '',
    username:'',
  },
  profile: {
    profile_image: null,
    bio: '', 
  },
};

const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case storeConstants.FETCH_USERS_REQUEST:
      return { ...state, loading: true, error: null };
    case storeConstants.FETCH_USERS_SUCCESS:
      return { ...state, loading: false, users: action.payload };
    case storeConstants.FETCH_USERS_FAILURE:
      return { ...state, loading: false, error: action.payload };
    case storeConstants.FETCH_USER_REQUEST:
      return { ...state, loading: true, error: null };
    case storeConstants.FETCH_USER_SUCCESS:
      return { ...state, loading: false, user: action.payload };
    case storeConstants.FETCH_USER_FAILURE:
      return { ...state, loading: false, error: action.payload };
    case storeConstants.CREATE_USER_REQUEST:
      return { ...state, loading: true, createUserSuccess: false, error: null };
    case storeConstants.CREATE_USER_SUCCESS:
      return { ...state, loading: false, createUserSuccess: true, user: action.payload };
    case storeConstants.CREATE_USER_FAILURE:
      return { ...state, loading: false, createUserSuccess: false, error: action.payload };
    case storeConstants.SET_TOKEN:
      return { ...state, token: action.payload };
    case storeConstants.RESET_TOKEN:
      return { ...state, token: action.payload };
    case storeConstants.SET_USER_ID:
      return { ...state, userId: action.payload };
    case storeConstants.SET_USER_INFO_MODAL_OPEN:
      return { ...state, modalOpen: action.payload };
    case storeConstants.SET_USER_INFO_MODAL_CONFIRM:
      return { ...state, confirmModal: action.payload };
    case storeConstants.SET_USER_INFO_NAME:
      return { ...state, first_name: action.payload };
    case storeConstants.SET_USER_INFO_SURNAME:
      return { ...state, last_name: action.payload };
    case storeConstants.FETCH_USER_PROFILE:
      return {
        ...state,
        profile: action.payload,
        error: null,
      };
    // case storeConstants.UPDATE_USER_PROFILE:
    //   return {
    //     ...state,
    //     profile: { ...state.profile, ...action.payload },
    //     error: null,
    //   };
    case storeConstants.UPLOAD_PROFILE_IMAGE:
      return {
        ...state,
        profile: { ...state.profile, profile_image: action.payload },
      };
    case storeConstants.UPDATE_PROFILE_BIO:
      return {
        ...state,
        profile: { ...state.profile, bio: action.payload },
      };
    case storeConstants.UPDATE_USER_PROFILE:
      return {
        ...state,
        user: action.payload.user,  // user state'ini doğrudan action.payload.user ile güncelle
        profile: { ...state.profile, ...action.payload.profile },
        error: null,
      };
    default:
      return state;
  }
};

export default userReducer;
