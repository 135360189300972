import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import storeConstants from '../redux/constans/actionTypes';

export default function CampaignTextCreator({ onTitleErrorChange, darkMode }) {
  const dispatch = useDispatch();
  const { templateText, selectedOption, campaignTitle } = useSelector((state) => state.campaignReducer);
  const formData = useSelector((state) => state.aiCampaignQuestionsReducer.formData);
  const loading = useSelector((state) => state.aiCampaignQuestionsReducer.loading); // loading durumu
  const [title, setTitle] = useState(campaignTitle || '');
  const [text, setText] = useState(templateText || '');
  const [institutionOrPerson, setInstitutionOrPerson] = useState(formData?.awarenessTargetInstitution || '');
  const [titleError, setTitleError] = useState('');
  const [textError, setTextError] = useState('');

  useEffect(() => {
    onTitleErrorChange(titleError);
  }, [titleError, onTitleErrorChange]);

  const updateCampaignText = (updates) => {
    dispatch({
      type: storeConstants.SET_CAMPAIGN_TEXT,
      payload: { ...updates, selectedOption, campaignTitle: title, templateText: text }
    });
  };

  const handleTitleChange = (event) => {
    const newTitle = event.target.value;
    setTitle(newTitle);
    if (newTitle.length < 25) {
      setTitleError('Başlık en az 25 karakter olmalıdır.');
    } else {
      setTitleError('');
    }
  };

  const handleInstitutionOrPersonChange = (event) => {
    const newInstitutionOrPerson = event.target.value;
    setInstitutionOrPerson(newInstitutionOrPerson);
    updateCampaignText({ institutionOrPersonName: newInstitutionOrPerson });
  };

  const handleCustomTextChange = (event) => {
    const newText = event.target.value;
    setText(newText);
    updateCampaignText({ templateText: newText });

    if (newText.length < 50) {
      setTextError('Kampanya metni en az 50 karakter olmalıdır.');
    } else {
      setTextError('');
    }
  };


  return (
    <div className={`relative w-full ${!loading ? 'pointer-events-none' : ''}`}>
      {!loading && (
        <div className="absolute inset-0 bg-gray-800 bg-opacity-50 flex items-center justify-center z-10">
          <div className="text-center text-white">
            <p className="mt-4 text-lg animate-fall">
              Yapay Zeka ile Kampanya Metni Oluşturuluyor
              <span className="animate-fall-dots">...</span>
            </p>
          </div>
        </div>
      )}

      <div className={`w-full ${!loading ? 'blur-sm' : ''}`}>
        <input
          type="text"
          value={title}
          onChange={handleTitleChange}
          placeholder="Kampanya başlığınızı buraya yazın..."
          className={`border ${darkMode ? 'border-gray-600 bg-gray-700' : 'border-gray-300'} rounded-md p-2 mb-3 w-full focus:outline-none focus:ring-2 focus:ring-blue-500`}
          required
        />
        {titleError && <p className="text-red-600">{titleError}</p>}

        <input
          type="text"
          value={institutionOrPerson}
          onChange={handleInstitutionOrPersonChange}
          placeholder="Kişi veya Kurum Adı"
          className={`border ${darkMode ? ' border-gray-600 bg-gray-700' : 'border-gray-300'} rounded-md p-2 mb-3 w-full focus:outline-none focus:ring-2 focus:ring-blue-500`}
          required
        />

        <div className="">
          {selectedOption === 'startFromScratch' && (
            <div>
              <textarea
                rows={6}
                value={text}
                onChange={handleCustomTextChange}
                placeholder="Kampanya metninizi buraya yazın..."
                className={`border ${darkMode ? 'border-gray-600 bg-gray-700' : 'border-gray-300'} rounded-md p-2 w-full focus:outline-none focus:ring-2 focus:ring-blue-500`}
                required
              />
              {textError && <p className="text-red-600">{textError}</p>}
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
