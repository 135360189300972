import storeConstants from "../constans/actionTypes";

const initialState = {
  selectedTopic: null,
};

const topicReducer = (state = initialState, action) => {
  switch (action.type) {
    case storeConstants.SET_SELECTED_TOPIC:
      return { ...state, selectedTopic: action.payload };
    case storeConstants.RESET_CAMPAIGN_STATE:
      return initialState;
    default:
      return state;
  }
};

export default topicReducer;