import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { styled } from '@mui/material/styles';
import storeConstants from '../redux/constans/actionTypes';

const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
});

export default function ImageUpload({ darkMode }) {
  const dispatch = useDispatch();
  const uploadedFiles = useSelector((state) => state.imageReducer.uploadedFiles) || [];
  console.log(uploadedFiles, "uploadedFiles");

  const [data, setData] = useState([]);

  useEffect(() => {
    setData(uploadedFiles);
  }, [uploadedFiles]);

  const handleFileChange = (event) => {
    const files = Array.from(event.target.files);
    
    if (data.length + files.length > 4) {
      alert('En fazla 4 dosya yükleyebilirsiniz.');
      return;
    }

    const newData = [...data, ...files];
    setData(newData);
    dispatch({
      type: storeConstants.SET_UPLOADED_FILE,
      payload: newData,
    });
  };

  const handleRemoveFile = (index) => {
    const newData = data.filter((_, i) => i !== index);
    setData(newData);
    dispatch({
      type: storeConstants.SET_UPLOADED_FILE,
      payload: newData,
    });
  };

  const renderPreview = () => {
    return (
      <div className="grid grid-cols-2 sm:grid-cols-2 md:grid-cols-4 gap-2 mt-2">
        {data.map((item, index) => {
          let fileUrl;
          let isVideo = false;
  
          if (item instanceof File) {
            fileUrl = URL.createObjectURL(item);
            isVideo = item.type.startsWith("video/"); // Video türünü kontrol et
          } else if (item && typeof item === "object" && item.file) {
            fileUrl = item.file;
            isVideo = item.is_video;
          } else {
            console.error("Invalid file:", item);
            return null;
          }
  
          return (
            <div key={index} className="relative border border-gray-500 rounded-[5px] p-2">
              {isVideo ? (
                <video
                  controls
                  src={fileUrl}
                  className="w-full h-auto max-h-40 object-contain"
                />
              ) : (
                <img
                  src={fileUrl}
                  alt={`Preview ${index}`}
                  className="w-full h-auto max-h-40 object-contain"
                />
              )}
              <button
                onClick={() => handleRemoveFile(index)}
                className="absolute top-0 right-0 border border-gray-500 text-gray-500 rounded-[5px] pr-2 pl-2"
              >
                &times;
              </button>
            </div>
          );
        })}
      </div>
    );
  };
  

  return (
    <div className={`w-full text-center`}>
      <label className={`flex items-center justify-center ${darkMode ? 'bg-blue-700' : 'bg-blue-700'} text-white rounded-md px-4 py-2 cursor-pointer transition duration-300 hover:bg-blue-700`}>
        <CloudUploadIcon className="mr-2" />
        Görsel veya Video Yükle
        <VisuallyHiddenInput
          type="file"
          accept="image/*,video/*"
          multiple
          onChange={handleFileChange}
        />
      </label>
      <div className="">
        {renderPreview()}
      </div>
    </div>
  );
}
