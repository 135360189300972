
import React from 'react';
import { Navigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

const PrivateRoute = ({ element: Element }) => {
    const token = useSelector(state => state.users.token);

    return token ? <Element /> : <Navigate to="/login" />;
};

export default PrivateRoute;