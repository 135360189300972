const initialState = {
    submitting: false,
    success: false,
    error: null
};

const contactReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'SUBMIT_CONTACT_REQUEST':
            return { ...state, submitting: true };
        case 'SUBMIT_CONTACT_SUCCESS':
            return { ...state, submitting: false, success: true };
        case 'SUBMIT_CONTACT_FAILURE':
            return { ...state, submitting: false, error: action.payload };
        default:
            return state;
    }
};

export default contactReducer;