import React, { useState } from 'react';
import { format } from 'date-fns';
import { tr } from 'date-fns/locale';
import { useSelector } from 'react-redux';
import CampaignShare from './CampaignShare';
import CampaignActions from './CampaignActions';
import CampaignEditAndDeleteButton from './CampaignEditAndDeleteButton';
import { Link } from "react-router-dom";

const formatDate = (dateString) => {
  if (!dateString) return 'Tarih mevcut değil';
  return format(new Date(dateString), 'd MMMM yyyy', { locale: tr });
};

const CampaignCard = ({
  campaign,
  onEmailClick,
  onPhoneCall,
  onDetailUser,
  onShare,
  onEditClick,
  onDeleteClick,
  textHeaderProps,
  textProps,
  buttonProps,
  show,
  darkMode,
  isDetailPage,
  isDetailPageDeleteEdit,
}) => {
  const userId = useSelector((state) => state?.users?.userId);
  const token = useSelector((state) => state?.users?.token);
  const [currentSlide, setCurrentSlide] = useState(0);

  const canEditOrDelete = Number(campaign?.user.id) === Number(userId);

  const handleThumbnailClick = (index) => {
    setCurrentSlide(index);
  };

  return (
    <div className={`flex justify-center`}>
      <div className={`flex flex-col w-full max-w-4xl bg-${darkMode ? 'gray-800' : 'white'} overflow-hidden rounded-[5px] mx-auto`}>

        <div className="flex items-center mb-2">
          {show && (
            <a
              href="#"
              onClick={() => onDetailUser(campaign.user.id)}
              className="flex items-center justify-center w-6 h-6 rounded-full bg-red-500 text-white mr-1"
            >
              {campaign.user.profile?.profile_image ? (
                <img
                  src={campaign.user.profile.profile_image}
                  className="w-full h-full object-cover rounded-full"
                  alt="Avatar"
                />
              ) : (
                <span className="text-lg font-bold">
                  {campaign.user.username?.charAt(0).toUpperCase()}
                </span>
              )}
            </a>
          )}

          <a
            onClick={() => onDetailUser(campaign.user.id)}
            className={`text-gray-400 text-xs hover:text-[#CA113D] ${textProps} overflow-hidden whitespace-nowrap text-ellipsis ${darkMode ? 'text-gray-300' : 'text-gray-400'}`}
            href="#"
            style={{ maxWidth: '300px' }}
          >
            {campaign.user.username}
          </a>
        </div>

        <h3 className={`text-gray-100 text-sm font-semibold ${textHeaderProps} ${darkMode ? 'text-gray-300' : 'text-gray-800'}`}>
          {campaign.title || "Learn CSS Box Model in 8 Minutes"}
        </h3>

        <div className="flex justify-between gap-4">
          <p className={`text-gray-400 text-xs ${textProps} truncate whitespace-nowrap`}>
            Kampanya konusu: {campaign.topic}
          </p>
          <p className={`text-gray-400 text-xs ${textProps} truncate whitespace-nowrap`}>
            Kişi veya kurum adı: {campaign.institution_or_person_name}
          </p>
        </div>

        {campaign?.media?.length > 0 ? (
  <div className="flex">
    {campaign.media.length === 1 ? (
      <>
        {campaign.media[0].is_video ? (
          <video
            controls
            // autoPlay
            muted
            className="h-[300px] w-full object-cover rounded-lg"
            src={campaign.media[0].file}
            alt={`Main media`}
            poster={campaign.media[0].poster }
            />
        ) : (
          <img
            className="h-[300px] w-full object-cover rounded-lg"
            src={campaign.media[0].file}
            alt={`Main media`}
          />
        )}
      </>
    ) : (
      <>
        <div className="w-3/4">
          {campaign.media[currentSlide].is_video ? (
            <video
              controls
              autoPlay
              muted
              className="h-[316px] w-full object-cover rounded-lg"
              src={campaign.media[currentSlide].file}
              alt={`Main media`}
              poster={campaign.media[0].poster }
              />
          ) : (
            <img
              className="h-[316px] w-full object-cover rounded-lg"
              src={campaign.media[currentSlide].file}
              alt={`Main media`}
            />
          )}
        </div>
        <div className="flex flex-col w-1/4 pr-1 pl-1">
          {campaign.media.slice(0, 3).map((media, index) => (
            <button
              key={index}
              onClick={() => handleThumbnailClick(index)}
              className="mb-2 focus:outline-none"
            >
              {media.is_video ? (
                <video
                  autoPlay
                  muted
                  className="h-[100px] w-full object-cover rounded-lg"
                  src={media.file}
                  alt={`Thumbnail ${index + 1}`}
                />
              ) : (
                <img
                  className="h-[100px] w-full object-cover rounded-lg"
                  src={media.file}
                  alt={`Thumbnail ${index + 1}`}
                />
              )}
            </button>
          ))}
        </div>
      </>
    )}
  </div>
) : (
  <div className="h-[300px] bg-gray-300 flex items-center justify-center">
    <p className="text-gray-500">Media yok</p>
  </div>
)}


        <div className={`flex flex-row justify-between gap-2 w-full ${darkMode ? 'text-gray-300' : 'text-gray-400'}`}>
          <p className={`text-xs ${textProps}`}>
            {formatDate(campaign.created_at)}
          </p>
          <p className={`text-xs ${textProps}`}>
            Kampanya kapsamı: {campaign.scope}
          </p>
        </div>

        <div className={`flex flex-col flex-grow ${textProps}`}>
          <p className={`${darkMode ? 'text-gray-300' : 'text-gray-800'}`}>
            {isDetailPage ? campaign.content || 'Açıklama mevcut değil.' : (
              <span className="line-clamp-3 text-ellipsis">
                {campaign.content || 'Açıklama mevcut değil.'}
              </span>
            )}
          </p>
        </div>

        {!isDetailPage && (
          <div>
            <Link to={`/campaigns/${campaign?.id}`} className="text-blue-600 underline hover:opacity-80">Daha fazla bilgi</Link>
          </div>
        )}

        {show && (
          <div className="pt-2 flex flex-col space-y-2"> {/* Using space-y-2 for vertical spacing between buttons */}
            <CampaignActions
              campaign={campaign}
              onEmailClick={onEmailClick}
              onPhoneCall={onPhoneCall}
              onShare={onShare}
            />
            {token && canEditOrDelete && isDetailPageDeleteEdit && !isDetailPage && (
              <CampaignEditAndDeleteButton
                campaign={campaign}
                onEditClick={onEditClick}
                onDeleteClick={onDeleteClick}
              />
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default CampaignCard;
