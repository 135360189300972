import storeConstants from "../constans/actionTypes";

const initialState = {
  selectedAiInfo: "Farkındalık Kampanyası",
  formData: {  
    campaignType: '',
    eventTime: '',
    institutionOrPersonName: '',
    city: '',
    district: '',
    neighborhood: '',
    street: '',
    affectedPeople: '',
    responsiblePeople: '',
    eventDescription: '',
    institution: '',
    regulationViolation: '',
    request: ''
  },
  timeData: { eventTime: '' }, 
  loading: false
};

const aiCampaignQuestionsReducer = (state = initialState, action) => {
  switch (action.type) {
    case storeConstants.SELECTED_CAMPAIGN_DETAIL_AI:
      return {
        ...state,
        selectedAiInfo: action.payload,
      };
    case storeConstants.SET_FORM_DATA:  
      return {
        ...state,
        formData: action.payload,
      };
    case storeConstants.SET_TIME_DATA:  
      return {
        ...state,
        timeData: action.payload,
      };
    case storeConstants.RESET_CAMPAIGN_STATE:
      return initialState;

    case storeConstants.SET_LOADING:
      return {
        ...state,
        loading: action.payload,
      };

    default:
      return state;
  }
};

export default aiCampaignQuestionsReducer;
