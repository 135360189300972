import React, { useEffect, useState } from 'react';
import { Modal, TextField, Button, Typography, Paper, Avatar, Grid, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useSelector, useDispatch } from 'react-redux';
import storeConstants from '../redux/constans/actionTypes';
import { updateUserProfile } from '../redux/ApiCalls';  // Import the API call function

const ProfileModal = ({ open, onClose }) => {
    const dispatch = useDispatch();
    const token = useSelector((state) => state?.users?.token);
    const user = useSelector((state) => state?.users?.user);

    const [formData, setFormData] = useState({
        first_name: '',
        last_name: '',
        bio: '',
        profile_image: null,
    });

    const [error, setError] = useState('');
    const [success, setSuccess] = useState('');

    useEffect(() => {
        if (open) {
            setFormData({
                first_name: user?.first_name || '',
                last_name: user?.last_name || '',
                bio: user?.bio || '',
                profile_image: null, // Reset image input
            });
        }
    }, [open, user]);

    const handleChange = (e) => {
        const { name, value, files } = e.target;
        if (name === 'profile_image') {
            setFormData({
                ...formData,
                [name]: files[0],
            });
        } else {
            setFormData({
                ...formData,
                [name]: value,
            });
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setError('');
        setSuccess('');

        const data = new FormData();
        data.append('user[first_name]', formData.first_name);
        data.append('user[last_name]', formData.last_name);
        data.append('profile[bio]', formData.bio);
        if (formData.profile_image) {
            data.append('profile[profile_image]', formData.profile_image);
        }
        try {
            const response = await updateUserProfile(data, token);
            if (response.data.error_message) {
                setError(response.data.error_message);
            } else {
                dispatch({
                    type: storeConstants.UPDATE_USER_PROFILE,
                    payload: {
                        user: response.data,
                    },
                });
                setSuccess('Profil başarıyla güncellendi.');
                onClose();
            }
        } catch {
            setError('Profil güncellenirken bir hata oluştu.');
        }
    };

    const avatarSrc = formData.profile_image
        ? URL.createObjectURL(formData.profile_image)
        : user?.profile_image || '/default-profile-pic.jpg';

    return (
        <Modal
            open={open}
            onClose={onClose}
            aria-labelledby="profile-modal-title"
            aria-describedby="profile-modal-description"
            style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
        >
            <Paper elevation={3} style={{ padding: '20px', width: '90%', maxWidth: '600px', position: 'relative' }}>
                <IconButton
                    edge="end"
                    color="inherit"
                    onClick={onClose}
                    aria-label="close"
                    style={{ position: 'absolute', right: 8, top: 8 }}
                >
                    <CloseIcon />
                </IconButton>
                <Typography variant="h5" component="h1" align="center">
                    Profilinizi Güncelleyin
                </Typography>
                <form onSubmit={handleSubmit} style={{ marginTop: '20px' }}>
                    <Grid container spacing={2} alignItems="center" justifyContent="center">
                        <Grid item xs={12} xxs={12} align="center">
                            <Avatar
                                src={avatarSrc}
                                style={{ width: '100px', height: '100px', marginBottom: '20px' }}
                            />
                            <label htmlFor="profile-image-upload" style={{ display: 'block' }}>
                                <span style={{
                                    display: 'inline-block',
                                    padding: '10px 20px',
                                    backgroundColor: '#3f51b5',
                                    color: '#fff',
                                    borderRadius: '4px',
                                    cursor: 'pointer',
                                    fontSize: '16px',
                                    textAlign: 'center'
                                }}>
                                    Profil Resmini Seç
                                </span>
                                <input
                                    id="profile-image-upload"
                                    type="file"
                                    name="profile_image"
                                    accept="image/*"
                                    onChange={handleChange}
                                    style={{ display: 'none' }}
                                />
                            </label>
                        </Grid>
                        <Grid item xs={12} xxs={12} sm={6}>
                            <TextField
                                label="Ad"
                                name="first_name"
                                value={formData.first_name}
                                onChange={handleChange}
                                fullWidth
                                variant="outlined"
                                margin="normal"
                                InputProps={{
                                    style: { fontSize: '14px' },
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} xxs={12} sm={6}>
                            <TextField
                                label="Soyad"
                                name="last_name"
                                value={formData.last_name}
                                onChange={handleChange}
                                fullWidth
                                variant="outlined"
                                margin="normal"
                                InputProps={{
                                    style: { fontSize: '14px' },
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} xxs={12}>
                            <TextField
                                label="Kendinizi Tanıtın"
                                name="bio"
                                value={formData.bio}
                                onChange={handleChange}
                                fullWidth
                                variant="outlined"
                                multiline
                                rows={4}
                                margin="normal"
                                InputProps={{
                                    style: { fontSize: '14px' },
                                }}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Button
                                type="submit"
                                variant="contained"
                                color="primary"
                                fullWidth
                                style={{ fontSize: '16px' }}
                            >
                                Güncelle
                            </Button>
                        </Grid>
                    </Grid>
                </form>
                {error && <Typography color="error" align="center" style={{ marginTop: '10px' }}>{error}</Typography>}
                {success && <Typography color="primary" align="center" style={{ marginTop: '10px' }}>{success}</Typography>}
            </Paper>
        </Modal>
    );
};

export default ProfileModal;
