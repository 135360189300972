
import React from 'react';
import PropTypes from 'prop-types';
import { Modal, Box, TextField, Button, Typography } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import storeConstants from '../redux/constans/actionTypes';
import MailIcon from '@mui/icons-material/Mail';

const UserInfoModal = ({ onConfirm }) => {
  const dispatch = useDispatch();
  const { modalOpen, first_name, last_name } = useSelector((state) => state.users);

  const handleNameChange = (e) => {
    dispatch({
        type: storeConstants.SET_USER_INFO_NAME,
        payload: e.target.value,
      });
  };

  const handleSurnameChange = (e) => {
    dispatch({
        type: storeConstants.SET_USER_INFO_SURNAME,
        payload: e.target.value,
      });
  };

  const handleConfirm = () => {
    onConfirm({ first_name, last_name });
    dispatch({
      type: storeConstants.SET_USER_INFO_MODAL_OPEN,
      payload: false,
    });
  };
  

  return (
    <Modal
      open={modalOpen}
      onClose={() => dispatch({
        type: storeConstants.SET_USER_INFO_MODAL_OPEN,
        payload: false,
      })}
      aria-labelledby="modal-title"
      aria-describedby="modal-description"
    >
      <Box sx={{
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 300,
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
        textAlign:"center"
      }}>
        <Typography id="modal-title" variant="h6" component="h2">
        </Typography>
        <TextField
          label="İsim"
          variant="outlined"
          fullWidth
          margin="normal"
          value={first_name}
          onChange={handleNameChange}
        />
        <TextField
          label="Soyisim"
          variant="outlined"
          fullWidth
          margin="normal"
          value={last_name}
          onChange={handleSurnameChange}
        />
        {/* <Button
          onClick={handleConfirm}
          variant="contained"
          color="primary"
          sx={{ mt: 2 ,justifyContent:"center"}}
        >
      Dilekçe gönder
        </Button> */}

        <button
        onClick={(e) => {
          handleConfirm
        }}
        className="relative border-2 duration-200 ease-in-out inline-flex justify-center items-center transition text-white border-blue-600 bg-blue-600 hover:bg-blue-700 hover:border-blue-700 rounded-md p-2 mt-2"
        aria-label="Send Email"
      >
        <span className="whitespace-nowrap text-xs">Dilekçe Gönder</span>
        <MailIcon className="w-4 h-4 mr-1" />
      
      </button>
      </Box>
    </Modal>
  );
};

UserInfoModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
};

export default UserInfoModal;
