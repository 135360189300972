import { useSelector, useDispatch } from 'react-redux';
import storeConstants from '../redux/constans/actionTypes';

const AiCampaignQuestions = () => {
  const selectedAiInfo = useSelector((state) => state.aiCampaignQuestionsReducer.selectedAiInfo);
  const dispatch = useDispatch();
  // const [timeData, setTimeData] = useState({ eventTime: '' });
  const timeData = useSelector((state) => state.aiCampaignQuestionsReducer.timeData);


  const getCardStyles = (selected) => {
    const isSelected = selectedAiInfo === selected;
    return `
      w-full p-4 py-2.5 text-sm rounded-lg cursor-pointer font-semibold text-center shadow-xs transition-all duration-300 
      ${isSelected ? 'bg-blue-500 text-white' : 'bg-gray-100 text-black'}
    `;
  };

  const formData = useSelector((state) => state.aiCampaignQuestionsReducer.formData); 

  const handleButtonClickDispatch = (selected) => {
    dispatch({
      type: storeConstants.SELECTED_CAMPAIGN_DETAIL_AI,
      payload: selected,
    });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    dispatch({
      type: storeConstants.SET_FORM_DATA,
      payload: { ...formData, [name]: value },
    });
  };


  const handleDateChange = (e) => {
    let value = e.target.value.replace(/\D/g, ''); 
    let formattedDate = '';
  
    if (value.length <= 2) {
      formattedDate = value;
    } else if (value.length <= 4) {
      formattedDate = value.slice(0, 2) + '/' + value.slice(2);
    } else if (value.length <= 6) {
      formattedDate = value.slice(0, 2) + '/' + value.slice(2, 4) + '/' + value.slice(4);
    } else {
      formattedDate = value.slice(0, 2) + '/' + value.slice(2, 4) + '/' + value.slice(4, 6);
    }
  
    dispatch({
      type: storeConstants.SET_TIME_DATA,
      payload: { eventTime: `${formattedDate} ${timeData.eventTime.split(' ')[1] || ''}` },
    });
  };

  const handleTimeChange = (e) => {
    let value = e.target.value.replace(/\D/g, ''); 
    let formattedTime = '';
  
    if (value.length <= 2) {
      formattedTime = value;
    } else {
      formattedTime = value.slice(0, 2) + ':' + value.slice(2, 4);
    }
  
    dispatch({
      type: storeConstants.SET_TIME_DATA,
      payload: { eventTime: `${timeData.eventTime.split(' ')[0]} ${formattedTime}` },
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
  };



  return (
    <>
      <div className="flex flex-col items-center w-full">
        <div className="flex flex-col sm:flex-row gap-4 w-full">
          <h2 className="text-2xl font-bold">Kampanya konusu detay girin</h2>
        </div>

        <div className="flex flex-col sm:flex-row gap-4 w-full mt-4">
          {['Farkındalık Kampanyası', 'Değişim Kampanyası', 'Şikayet Kampanyası'].map(selected => (
            <div
              key={selected}
              className={getCardStyles(selected)}
              role="alert"
              onClick={() => handleButtonClickDispatch(selected)}
            >
              <p className="text-xl text-center sm:text-2xl font-medium">{selected}</p>
            </div>
          ))}
        </div>
      </div>

      <div className="p-6 w-full mx-auto bg-white space-y-4">
        <form onSubmit={handleSubmit} className="space-y-4">
          {selectedAiInfo === 'Farkındalık Kampanyası' && (
            <>
              <h2 className="text-lg font-semibold text-center">Farkındalık Kampanyası Detayları</h2>

              {/* Main Campaign Topic */}
              <div>
                <input
                  type="text"
                  name="awarenessCampaignTopic"
                  value={formData.awarenessCampaignTopic}
                  onChange={handleChange}
                  placeholder="Farkındalık yaratmak istediğiniz konu nedir?"
                  className="mt-1 block w-full sm:w-4/4  px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                />
                <small className="text-gray-500">Örn: Çevre kirliliği, sosyal eşitsizlik vb.</small>
              </div>

              <div>
                <textarea
                  name="mainMessages"
                  value={formData.mainMessages}
                  onChange={handleChange}
                  placeholder="Bu kampanya ile vermek istediğiniz ana mesajlar nelerdir?"
                  className="mt-1 block w-full sm:w-4/4 min-h-[100px] px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                ></textarea>
                <small className="text-gray-500">Örn: Temiz bir çevre için atık yönetimi, geri dönüşümün önemi vb.</small>
              </div>

              {/* Target Institution or Organization */}
              <div>
                <input
                  type="text"
                  name="awarenessTargetInstitution"
                  value={formData.awarenessTargetInstitution}
                  onChange={handleChange}
                  placeholder="Bu kampanyayı duyurmak istediğiniz kurum nedir?"
                  className="mt-1 block w-full sm:w-4/4  px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                />
                <small className="text-gray-500">Örn: Çevre Bakanlığı, belediyeler vb.</small>
              </div>

              {/* Event Description */}

            </>
          )}


          {selectedAiInfo === 'Değişim Kampanyası' && (
            <>
              <h2 className="text-lg font-semibold text-center">Değişim Kampanyası Detayları</h2>

              <div>
                <input
                  type="text"
                  name="changeSubject"
                  value={formData.changeSubject}
                  onChange={handleChange}
                  placeholder="Hangi konuyla ilgili mevzuatın ya da uygulamanın değiştirilmesini istiyorsunuz?"
                  className="mt-1 block w-full sm:w-4/4  px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                />
                <small className="text-gray-500">Örn: Çalışma saatleri yasası, çevre koruma yönetmelikleri vb.</small>
              </div>

              <div>
                <textarea
                  name="changeMainMessages"
                  value={formData.changeMainMessages}
                  onChange={handleChange}
                  placeholder="Bu değişiklikle elde etmek istediğiniz sonuç nedir?"
                  className="mt-1 block w-full sm:w-4/4 min-h-[100px] px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                ></textarea>
                <small className="text-gray-500">Örn: İşçilerin çalışma koşullarını iyileştirmek, çevreye verilen zararı azaltmak vb.</small>
              </div>

              <div>
                <input
                  type="text"
                  name="changeTargetInstitution"
                  value={formData.changeTargetInstitution}
                  onChange={handleChange}
                  placeholder="Değişim yapmasını istediğiniz kurum nedir?"
                  className="mt-1 block w-full sm:w-4/4  px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                />
                <small className="text-gray-500">Örn: Çalışma Bakanlığı, Çevre ve Şehircilik Bakanlığı vb.</small>
              </div>


            </>
          )}

          {selectedAiInfo === 'Şikayet Kampanyası' && (
            <>
              <h2 className="text-lg font-semibold text-center">Olay Bilgilerini Girin</h2>

              <div className="flex space-x-4">
                <div className="w-full">
                  <input
                    type="text"
                    name="eventDate"
                    value={timeData.eventTime.split(' ')[0] || ''}
                    onChange={handleDateChange}
                    placeholder="GG/AA/YY"
                    className="mt-1 block w-full sm:w-4/4  px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                  />
                  <small className="text-gray-500">Örn: 10/15/23</small>
                </div>

                <div className="w-full">
                  <input
                    type="text"
                    name="eventTime"
                    value={timeData.eventTime.split(' ')[1] || ''}
                    onChange={handleTimeChange}
                    placeholder="SS:DD"
                    className="mt-1 block w-full sm:w-4/4  px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                  />
                  <small className="text-gray-500">Örn: 14:30</small>
                </div>
              </div>
              <div className="grid grid-cols-1 sm:grid-cols-4 gap-4">
                <div>
                  <input
                    type="text"
                    name="city"
                    value={formData.city}
                    onChange={handleChange}
                    placeholder="İl"
                    className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                  />
                  <small className="text-gray-500">Örn: İstanbul</small>
                </div>

                <div>
                  <input
                    type="text"
                    name="district"
                    value={formData.district}
                    onChange={handleChange}
                    placeholder="İlçe"
                    className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                  />
                  <small className="text-gray-500">Örn: Beyoğlu</small>
                </div>

                <div>
                  <input
                    type="text"
                    name="neighborhood"
                    value={formData.neighborhood}
                    onChange={handleChange}
                    placeholder="Mahalle"
                    className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                  />
                  <small className="text-gray-500">Örn: Cihangir Mahallesi</small>
                </div>

                <div>
                  <input
                    type="text"
                    name="street"
                    value={formData.street}
                    onChange={handleChange}
                    placeholder="Sokak"
                    className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                  />
                  <small className="text-gray-500">Örn: Sıraselviler Caddesi</small>
                </div>
              </div>


              <div>
                <input
                  type="text"
                  name="affectedPeople"
                  value={formData.affectedPeople}
                  onChange={handleChange}
                  placeholder="Olaydan zarar görenler?"
                  className="mt-1 block w-full sm:w-4/4  px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                />
                <small className="text-gray-500">    Örn: Bölgedeki esnaf, halk, hayvanlar, doğa.
                </small>
              </div>

              <div>
                <input
                  type="text"
                  name="perpetrators"
                  value={formData.perpetrators}

                  onChange={handleChange}
                  placeholder="Zarara neden olanlar?"
                  className="mt-1 block w-full sm:w-4/4  px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                />
                <small className="text-gray-500">Örn: Belediye yetkilileri.</small>
              </div>

              <div>
                <textarea
                  name="eventDescription"
                  value={formData.eventDescription}
                  onChange={handleChange}
                  placeholder="Olayı açıklayın"
                  className="mt-1 block w-full sm:w-4/4  min-h-[150px] px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                ></textarea>
                <small className="text-gray-500">Örn: Olayın detaylarını buraya yazın, ne olduğu, nasıl geliştiği vs.</small>
              </div>
              <div>
                <input
                  type="text"
                  name="complaintTargetInstitution"
                  value={formData.complaintTargetInstitution}
                  onChange={handleChange}
                  placeholder="Şikayet etmek istediğiniz kurum istediğiniz kurum nedir?"
                  className="mt-1 block w-full sm:w-4/4  px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                />
                <small className="text-gray-500">Örn: Çalışma Bakanlığı, Çevre ve Şehircilik Bakanlığı vb.</small>
              </div>
            </>
          )}
        </form>
      </div>
    </>
  );
};

export default AiCampaignQuestions;
