import React, { useEffect, useState } from "react";

const NotificationWrapper = ({ visible, darkMode }) => {
  const [modalVisible, setModalVisible] = useState(false);

  useEffect(() => {
    if (visible) {
      const timer = setTimeout(() => {
        setModalVisible(true); // Show the modal after 1 second
      }, 1000);

      return () => clearTimeout(timer);
    } else {
      setModalVisible(false); // Hide modal if 'visible' is false
    }
  }, [visible]);

  const closeModal = () => {
    setModalVisible(false); // Close modal when called
  };

  return (
    <div className="w-full relative">
      {modalVisible && ( // Conditionally render the modal
        <div
          id="pd-basic-modal"
          className="pd-overlay w-full h-full fixed top-0 left-0 z-[60] overflow-x-hidden overflow-y-auto"
        >
          <div className="opacity-100 ease-out sm:max-w-lg sm:w-full m-5 sm:mx-auto">
            <div className={`flex flex-col ${darkMode ? 'bg-gray-800' : 'bg-white'} rounded-2xl py-4 px-5`}>
              <div className="flex justify-between items-center pb-4 border-b border-gray-200">
                <h4 className="text-sm text-gray-900 font-medium">Modal simple</h4>
                <button className="block cursor-pointer close-modal-button" onClick={closeModal}>
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M7.75732 7.75739L16.2426 16.2427M16.2426 7.75739L7.75732 16.2427"
                      stroke="black"
                      strokeWidth="1.6"
                      strokeLinecap="round"
                    ></path>
                  </svg>
                </button>
              </div>
              <div className="overflow-y-auto py-4 min-h-[100px]">
                <p className="text-gray-600 text-sm">
                  This is the regular modal with supporting text below as additional content.
                </p>
              </div>
              <div className="flex items-center justify-end pt-4 border-t border-gray-200 space-x-4">
                <button
                  type="button"
                  className="pd-dropdown-toggle py-2.5 px-5 text-xs bg-indigo-50 text-indigo-500 rounded-full cursor-pointer font-semibold text-center shadow-xs transition-all duration-500 hover:bg-indigo-100"
                  onClick={closeModal}
                >
                  Cancel
                </button>
                <button
                  type="button"
                  className="py-2.5 px-5 text-xs bg-indigo-500 text-white rounded-full cursor-pointer font-semibold text-center shadow-xs transition-all duration-500 hover:bg-indigo-700"
                  onClick={closeModal}
                >
                  Okay, got it
                </button>
              </div>
            </div>
          </div>
          <div
            id="backdrop"
            className="fixed top-0 left-0 w-full h-full bg-black/50 z-[50]"
            onClick={closeModal} // Close modal when backdrop is clicked
          ></div>
        </div>
      )}
    </div>
  );
};

export default NotificationWrapper;
