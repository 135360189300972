

const storeConstants = {

// Login, Register
FETCH_USERS_REQUEST : 'FETCH_USERS_REQUEST',
FETCH_USERS_SUCCESS : 'FETCH_USERS_SUCCESS',
FETCH_USERS_FAILURE : 'FETCH_USERS_FAILURE',

FETCH_USER_REQUEST : 'FETCH_USER_REQUEST',
FETCH_USER_SUCCESS : 'FETCH_USER_SUCCESS',
FETCH_USER_FAILURE : 'FETCH_USER_FAILURE',

FETCH_USER_PROFILE:'FETCH_USER_PROFILE',
UPDATE_USER_PROFILE:'UPDATE_USER_PROFILE',
UPLOAD_PROFILE_IMAGE: 'UPLOAD_PROFILE_IMAGE',
UPDATE_PROFILE_BIO: 'UPDATE_PROFILE_BIO',

RESET_TOKEN:'RESET_TOKEN',

CREATE_USER_REQUEST : 'CREATE_USER_REQUEST',
CREATE_USER_SUCCESS : 'CREATE_USER_SUCCESS',
CREATE_USER_FAILURE : 'CREATE_USER_FAILURE',
SET_TOKEN:'SET_TOKEN',
SET_USER_ID :'SET_USER_ID',


SET_CURRENT_STEPPER : 'SET_CURRENT_STEPPER',
SET_CURRENT_STEP : 'SET_CURRENT_STEP',
SET_SELECTED_CARD:'SET_SELECTED_CARD',
NEXT_STEP:'NEXT_STEP',
PREVIOUS_STEP:'PREVIOUS_STEP',

SET_LOCAL_STEP: 'SET_LOCAL_STEP',  


SET_ACTIVE_TAB:"SET_ACTIVE_TAB",

SELECT_SCOPE : 'SELECT_SCOPE',

SET_INPUT_VALUE_LOCATION:'SET_INPUT_VALUE_LOCATION',
SET_OPTIONS:'SET_OPTIONS',
SET_SELECTED_LOCATION:'SET_SELECTED_LOCATION',
SET_TIME_DATA: 'SET_TIME_DATA',
SELECTED_CAMPAIGN_DETAIL_AI:'SELECTED_CAMPAIGN_DETAIL_AI',
SET_FORM_DATA:"SET_FORM_DATA",
RESET_CAMPAIGN_STATE:"RESET_CAMPAIGN_STATE",
 SET_LOADING: 'SET_LOADING',

SET_SELECTED_TOPIC:'SET_SELECTED_TOPIC',
SET_CAMPAIGN_TEXT: 'SET_CAMPAIGN_TEXT',
SET_UPLOADED_FILE:'SET_UPLOADED_FILE',
SET_BOOLEAN_CAMPAIGN:'SET_BOOLEAN_CAMPAIGN',
SET_TYPE: 'SET_TYPE',
SET_INSTITUTION_OR_PERSON_NAME: 'SET_INSTITUTION_OR_PERSON_NAME',

SET_UPLOADED_IMAGE :'SET_UPLOADED_IMAGE',
SET_UPLOADED_VIDEO:'SET_UPLOADED_VIDEO',
SET_CAMPAIGN_FORM_DATA:'SET_CAMPAIGN_FORM_DATA',
SET_CAMPAIGN_ID:'SET_CAMPAIGN_ID',
SET_CAMPAIGNS:'SET_CAMPAIGNS',
RESET_CAMPAIGN_STATE:'RESET_CAMPAIGN_STATE',

SET_USER_INFO_MODAL_OPEN : 'SET_USER_INFO_MODAL_OPEN',
SET_USER_INFO_NAME : 'SET_USER_INFO_NAME',
SET_USER_INFO_SURNAME : 'SET_USER_INFO_SURNAME',
SET_USER_INFO_MODAL_CONFIRM:'SET_USER_INFO_MODAL_CONFIRM',


}
export default storeConstants