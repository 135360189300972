import React, { useCallback, useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { fetchUserDetails, trackClick } from '../redux/ApiCalls';
import CampaignCard from '../components/CampaignCard';
import UserInfoModal from '../components/UserInfoModal';
import ProfileModal from '../components/UserProfileModal';
import { useDispatch, useSelector } from 'react-redux';
import storeConstants from '../redux/constans/actionTypes';

const UserDetailAndCampaignDetail = ({ darkMode}) => {
  const { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [campaignsAndUser, setCampaignsAndUser] = useState(null);
  const [selectedCampaign, setSelectedCampaign] = useState(null);
  const [isProfileModalOpen, setIsProfileModalOpen] = useState(false);
  const [isCompleteProfileModalOpen, setIsCompleteProfileModalOpen] = useState(false);

  const token = useSelector((state) => state?.users?.token);
  const profile = useSelector((state) => state?.users?.profile);
  const user = useSelector((state) => state?.users?.user);
  const userId = useSelector((state) => state?.users?.userId);

  const loadUserData = useCallback(async () => {
    try {
      const userResponse = await fetchUserDetails(id);
      setCampaignsAndUser(userResponse.data);
      dispatch({
        type: storeConstants.UPDATE_USER_PROFILE,
        payload: {
          user: userResponse.data,
        },
      });
    } catch (error) {
    } finally {
    }
  }, [id, dispatch]);

  useEffect(() => {
    loadUserData();
  }, [loadUserData]);



  const handleEditClick = (campaign) => {

    dispatch({
      type: storeConstants.SET_CAMPAIGN_ID,
      payload: { campaignId: campaign.id },
    });
    dispatch({
      type: storeConstants.SET_INPUT_VALUE_LOCATION,
      payload: campaign.location,
    });
    dispatch({
      type: storeConstants.SELECT_SCOPE,
      payload: campaign.scope,
    });
    dispatch({
      type: storeConstants.SET_CAMPAIGN_TEXT,
      payload: { templateText: campaign.content, campaignTitle: campaign.title },
    });
    dispatch({
      type: storeConstants.SET_SELECTED_TOPIC,
      payload: campaign.topic,
    });
   dispatch({
    type: storeConstants.SET_UPLOADED_IMAGE,
    payload: campaign.media[0].file || null,
  });
    const formData = {
      phone_number: campaign.phone_number || '',
      email: campaign.email || '',
      cc: campaign.cc || '',
      bcc: campaign.bcc || '',
      subject: campaign.subject || '',
      message: campaign.message || '',
      button_name: campaign.button_name || '',
    };

    Object.keys(formData).forEach(key => {
      dispatch({
        type: storeConstants.SET_CAMPAIGN_FORM_DATA,
        payload: { [key]: formData[key] },
      });
    });
    navigate(`/edit-campaign/${campaign.id}`);
  };

  const handleCardHeaderClick = () => {
    if (token && userId === Number(id)) {
      setIsProfileModalOpen(true);
    } else {
      console.log('Sadece kendi profiliniz için profil modalını açabilirsiniz.');
    }
  };


  const campaigns = campaignsAndUser?.campaigns || [];

  const handleEmailClick = (campaign) => {
    setSelectedCampaign(campaign);
    dispatch({
      type: storeConstants.SET_USER_INFO_MODAL_OPEN,
      payload: true,
    });
  };

  const handleConfirm = ({ first_name, last_name }) => {
    trackClick(selectedCampaign.id)
      .then(() => {
        const emailLink = `mailto:${selectedCampaign.email}?cc=${selectedCampaign.cc || ''}&bcc=${selectedCampaign.bcc || ''}&subject=${encodeURIComponent(selectedCampaign.subject || '')}&body=${encodeURIComponent(selectedCampaign.message || '')}%0A%0A ${first_name} ${last_name}`;
        window.location.href = emailLink;
        !token && (setTimeout(() => {
          navigate('/register');
        }, 1000));
      })
      .catch((error) => console.error('Error tracking click:', error));
  };

  const handlePhoneCall = (phoneNumber) => {
    const sanitizedPhoneNumber = phoneNumber.replace(/[^\d]/g, '');
    if (sanitizedPhoneNumber) {
      window.location.href = `tel:${sanitizedPhoneNumber}`;
    } else {
      console.error("No valid phone number available");
    }
  };
//   const handleShare = () => {
//     const shareTitle = campaign.title || 'Başlık Yok';
//     const shareUrl = `${window.location.origin}/campaigns/${campaign.id}`;
    
//     // Django'dan gelen meta bilgileri
//     const shareDescription = campaign.meta.description || 'Açıklama Yok';
//     const shareImage = campaign.meta.image || ''; // Görsel URL'si

//     if (navigator.share) {
//         navigator.share({
//             title: shareTitle,
//             text: shareDescription,
//             url: shareUrl,
//             // Görsel için destek yok
//         })
//         .then(() => console.log('Paylaşım başarılı.'))
//         .catch((error) => {
//             console.error('Paylaşım hatası:', error);
//             alert('Paylaşım sırasında bir hata oluştu.');
//         });
//     } else {
//         const shareText = `${shareTitle}\n${shareDescription}\n${shareUrl}`;
//         navigator.clipboard.writeText(shareText)
//         .then(() => alert('Kampanya bağlantısı panoya kopyalandı!'))
//         .catch((error) => {
//             console.error('Panoya kopyalama hatası:', error);
//             alert('Panoya kopyalama sırasında bir hata oluştu.');
//         });
//     }
// };
 const handleShare = () => {
    const shareTitle = campaigns.title || 'Başlık Yok';
    const shareUrl = `${window.location.origin}/campaigns/${campaigns.id}`;
    
    // Django'dan gelen meta bilgileri
    const shareDescription = campaigns.meta.description || 'Açıklama Yok';
    const shareImage = campaigns.meta.image || ''; // Görsel URL'si

    if (navigator.share) {
        navigator.share({
            title: shareTitle,
            text: shareDescription,
            url: shareUrl,
            // Görsel için destek yok
        })
        .then(() => console.log('Paylaşım başarılı.'))
        .catch((error) => {
            console.error('Paylaşım hatası:', error);
            alert('Paylaşım sırasında bir hata oluştu.');
        });
    } else {
        const shareText = `${shareTitle}\n${shareDescription}\n${shareUrl}`;
        navigator.clipboard.writeText(shareText)
        .then(() => alert('Kampanya bağlantısı panoya kopyalandı!'))
        .catch((error) => {
            console.error('Panoya kopyalama hatası:', error);
            alert('Panoya kopyalama sırasında bir hata oluştu.');
        });
    }
};

  const calculateProfileCompletionPercentage = () => {
    if (!profile) return 0;

    const totalFields = 4;
    let completedFields = 0;

    if (user?.first_name) completedFields++;
    if (user?.last_name) completedFields++;
    if (user?.profile_image) completedFields++;
    if (user?.bio) completedFields++;

    return (completedFields / totalFields) * 100;
  };

  const profileCompletionPercentage = calculateProfileCompletionPercentage();


  const displayName = user?.first_name || user?.last_name
    ? `${user?.first_name || ''} ${user?.last_name || ''}`.trim()
    : user?.username || 'Profil';


  return (
    <div className={`max-w-xl mx-auto px-2 pt-20 pb-8 p-2 mt-8 ${darkMode ? 'bg-[#091840]' : 'bg-white'} flex flex-col items-center justify-center w-full pt-24 mt-8`}>
      <div className="container max-w-lg p-4 shadow-md bg-white rounded">
        {userId === Number(id) && token && (
          <>
            <div className="text-gray-600 mb-2">
              Profil Tamamlanma Yüzdesi: {Math.round(profileCompletionPercentage)}%
            </div>
            <div className="bg-gray-200 h-2 rounded">
              <div
                className="bg-blue-500 h-full rounded"
                style={{ width: `${profileCompletionPercentage}%` }}
              />
            </div>
          </>
        )}

        <div className="flex flex-col items-center ">

          <a href="#" onClick={handleCardHeaderClick} className="flex items-center justify-center w-10 h-10 rounded-full bg-red-500 text-white mt-4">
            {user.profile_image ? <img
              src={user?.profile_image}
              className="w-full h-full object-cover rounded-full"
            /> : <span className="text-lg font-bold">
              {user.username?.charAt(0).toUpperCase()}
            </span>}
          </a>
          {/* <div
            onClick={handleCardHeaderClick}
            className={`w-16 h-16 cursor-pointer bg-red-500 rounded-full flex items-center justify-center`}
          >
            <img src={user?.profile_image} alt="Profile" className="h-full w-full rounded-full" />
            {!user?.profile_image && displayName[0]}
          </div> */}

          <div className="text-gray-600 mt-2 text-center">
            {user?.username} 
          </div>
          <div className="text-gray-600 text-center mt-1 whitespace-pre-wrap">
            {user?.bio}
          </div>

          {userId === Number(id) && token && (
            <button
              className="mt-2 px-4 py-2 bg-blue-500 text-white rounded"
              onClick={handleCardHeaderClick}
            >
              Profilini Düzenle
            </button>
          )}
        </div>
      </div>

      {campaigns.length > 0 ? (
        <h2 className={`w-full md:w-4/5 mt-8 ${darkMode ? 'bg-[#ca113d]' : 'bg-[#091840]'} 
        text-white text-lg sm:text-xl md:text-2xl font-bold text-center py-2 px-4 mb-2 rounded-lg`}>
          MEVCUT KAMPANYALAR
        </h2>
      ) : (
        <div className="py-4 text-center ">Kullanıcı için kampanya bulunamadı.</div>
      )}
           

      <div className="w-full border-b border-gray-300 ">
        {campaigns.length > 0 && campaigns.map(campaign => (
          <CampaignCard
            campaign={campaign}
            onEmailClick={handleEmailClick}
            onPhoneCall={handlePhoneCall}
            onDetailUser={() => { }}
            onBoxClick={() => { }}
            isDetailPage={false}
            isDetailPageDeleteEdit={true}
            onShare={handleShare}
            onEditClick={() => handleEditClick(campaign)}
            show={true}
          />

        ))}
      </div>

      <UserInfoModal onConfirm={handleConfirm} />
      <ProfileModal
        open={isProfileModalOpen || isCompleteProfileModalOpen}
        onClose={() => {
          setIsProfileModalOpen(false);
          setIsCompleteProfileModalOpen(false);
        }}
      />
    </div>
  );
};

export default UserDetailAndCampaignDetail;
