import React from 'react';
import ReactDOM, { hydrateRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import App from './App';
import { configureStore, persistor } from './redux/store';
import { PersistGate } from 'redux-persist/integration/react';
import { createTheme } from '@mui/material/styles';
import { ThemeProvider } from '@mui/material/styles';
import LangProvider from './language/LangProvider';

const theme = createTheme({
  breakpoints: {
    values: {
      xxs: 0,
      xs: 390,
      sm: 600,
      md: 1200,
      lg: 1536,
    },
  },
});

const container = document.getElementById('root');

if (container.hasChildNodes()) {
  persistor.subscribe(() => {
    const { bootstrapped } = persistor.getState();
    if (bootstrapped) {
      const root = hydrateRoot(container, (
        <Provider store={configureStore}>
          <ThemeProvider theme={theme}>
            <LangProvider>
              <App />
            </LangProvider>
          </ThemeProvider>
        </Provider>
      ));
    }
  });
} else {
  const root = ReactDOM.createRoot(container);
  root.render((
    <Provider store={configureStore}>
      <PersistGate persistor={persistor}>
        <ThemeProvider theme={theme}>
          <LangProvider>
            <App />
          </LangProvider>
        </ThemeProvider>
      </PersistGate>
    </Provider>
  ));
}
