// // import storeConstants from "../constans/actionTypes";

// // const initialState = {
// //   customText: '',
// //   templateText: '',
// //   selectedOption: 'startFromScratch',
// //   campaignTitle: null, 
// //   campaignId: '',
// //   institutionOrPersonName:'',
// //   booleanValueCampaign:false,
// //   campaignTitleAi:'',
// //   textAi:'',

// //   formData: {
// //     phone_number:'',
// //     email: '',
// //     cc: '',
// //     bcc: '',
// //     subject: '',
// //     message: '',
// //     button_name: ''
// //   },
// // };

// // const campaignReducer = (state = initialState, action) => {
// //   switch (action.type) {
// //     case storeConstants.SET_CAMPAIGN_TEXT:
// //       return {
// //         ...state,
// //         ...action.payload,
// //       };
// //     case storeConstants.SET_CAMPAIGN_FORM_DATA:
// //       return {
// //         ...state,
// //         formData: {
// //           ...state.formData,
// //           ...action.payload,
// //         },
// //       };
// //       case storeConstants.SET_CAMPAIGN_ID:
// //       return {
// //         ...state,
// //         campaignId: action.payload.campaignId,
// //       };
// //       case storeConstants.RESET_CAMPAIGN_STATE:
// //         return initialState;

// //     default:
// //       return state;
// //   }
// // };

// // export default campaignReducer;
// import storeConstants from "../constans/actionTypes";

// const initialState = {
//   customText: '',
//   templateText: '',
//   selectedOption: 'startFromScratch',
//   campaignTitle: null, 
//   campaignId: '',
//   institutionOrPersonName: '',
//   booleanValueCampaign: false,
//   campaignTitleAi: '',
//   textAi: '',
//   uploadedFiles: [], 

//   formData: {
//     phone_number: '',
//     email: '',
//     cc: '',
//     bcc: '',
//     subject: '',
//     message: '',
//     button_name: ''
//   },
// };

// const campaignReducer = (state = initialState, action) => {
//   switch (action.type) {
//     case storeConstants.SET_CAMPAIGN_TEXT:
//       return {
//         ...state,
//         ...action.payload,
//       };
//     case storeConstants.SET_CAMPAIGN_FORM_DATA:
//       return {
//         ...state,
//         formData: {
//           ...state.formData,
//           ...action.payload,
//         },
//       };
//     case storeConstants.SET_CAMPAIGN_ID:
//       return {
//         ...state,
//         campaignId: action.payload.campaignId,
//       };
//     case storeConstants.SET_UPLOADED_FILE:
//       return {
//         ...state,
//         uploadedFiles: action.payload, 
//       };
//     case storeConstants.RESET_CAMPAIGN_STATE:
//       return initialState;
//     default:
//       return state;
//   }
// };

// export default campaignReducer;


import storeConstants from "../constans/actionTypes";

const initialState = {
  customText: '',
  templateText: '',
  selectedOption: 'startFromScratch',
  campaignTitle: null, 
  campaignId: '',
  institutionOrPersonName: '',
  booleanValueCampaign: false,
  campaignTitleAi: '',
  textAi: '',
  uploadedFiles: [], 
  // campaigns: [], 
  formData: {
    phone_number: '',
    email: '',
    cc: '',
    bcc: '',
    subject: '',
    message: '',
    button_name: ''
  },
};

const campaignReducer = (state = initialState, action) => {
  switch (action.type) {
    case storeConstants.SET_CAMPAIGN_TEXT:
      return {
        ...state,
        ...action.payload,
      };
    case storeConstants.SET_CAMPAIGN_FORM_DATA:
      return {
        ...state,
        formData: {
          ...state.formData,
          ...action.payload,
        },
      };
    case storeConstants.SET_CAMPAIGN_ID:
      return {
        ...state,
        campaignId: action.payload.campaignId,
      };
    case storeConstants.SET_UPLOADED_FILE:
      return {
        ...state,
        uploadedFiles: action.payload,
      };
    case storeConstants.SET_CAMPAIGNS:
      const campaigns = action.payload;
      
      const firstCampaign = campaigns[0] || {};

      return {
        ...state,
        campaigns, 
        scope: firstCampaign.scope || '',
        location: firstCampaign.location || '',
        topic: firstCampaign.topic || '',
        institutionOrPersonName: firstCampaign.institution_or_person_name || '',
        phone_number: firstCampaign.phone_number || '',
        email: firstCampaign.email || '',
        cc: firstCampaign.cc || '',
        bcc: firstCampaign.bcc || '',
        subject: firstCampaign.subject || '',
        message: firstCampaign.message || '',
        click_count: firstCampaign.click_count || 0,
        click_count_phone_number: firstCampaign.click_count_phone_number || 0,
        created_at: firstCampaign.created_at || '',
        username: firstCampaign.username || '',
        first_name: firstCampaign.first_name || '',
        last_name: firstCampaign.last_name || '',
        bio: firstCampaign.bio || '',
        profile_image: firstCampaign.profile_image || '',
      };
    case storeConstants.RESET_CAMPAIGN_STATE:
      return initialState;
    default:
      return state;
  }
};


export default campaignReducer;
