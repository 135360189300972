import React from 'react';

const AdminLink = () => {
    const adminUrl = `${process.env.REACT_APP_REST_URL}admin/`;

    return (
        <div className="mt-40 ml-4" >
            <h1 >Admin Paneline Erişim</h1>
            <a style={{color:"blue"}} href={adminUrl} target="_blank"  rel="noopener noreferrer">
                Django Admin Paneline Git
            </a>
        </div>
    );
};

export default AdminLink;
