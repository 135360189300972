import React from 'react';
import PropTypes from 'prop-types';
import MailIcon from '@mui/icons-material/Mail';
import PhoneIcon from '@mui/icons-material/Phone';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

const CampaignActions = ({ campaign, onEmailClick, onPhoneCall, onShare }) => {
  const navigate = useNavigate();
  const token = useSelector((state) => state?.users?.token);


  const handleSignupClick = () => {
    navigate('/register');
  };

  return (
    <div className="flex justify-evenly gap-2 pb-3 w-full max-w-sm mx-auto">
      <button
        onClick={(e) => {
          e.stopPropagation();
          onEmailClick(campaign);
        }}
        className="relative border-2 duration-200 ease-in-out inline-flex justify-center items-center transition font-medium text-xs w-25h-8 text-white border-blue-600 bg-blue-600 hover:bg-blue-700 hover:border-blue-700 rounded-md p-1"
        aria-label="Send Email"
      >
        <span className="whitespace-nowrap text-xs">Dilekçe Gönder</span>
        <MailIcon className="w-4 h-4 mr-1" />
        <span className="absolute top-8 right-2 px-1 transform translate-x-1/2 -translate-y-1/2 bg-red-500 text-white rounded-full text-xs">
          {campaign.click_count || '0'}
        </span>
      </button>

      <button
        onClick={(e) => {
          e.stopPropagation();
          onPhoneCall(campaign.phone_number, campaign.id);
        }}
        className="relative border-2 duration-200 ease-in-out inline-flex justify-center items-center transition font-medium text-xs w-24 h-8 text-white border-blue-600 bg-blue-600 hover:bg-blue-700 hover:border-blue-700 rounded-md p-1"
        aria-label="Make Phone Call"
      >
        <span className="whitespace-nowrap text-xs">Telefon Aç</span>

        <PhoneIcon className="w-4 h-4 mr-1" />
        <span className="absolute top-8 right-2 px-1 transform translate-x-1/2 -translate-y-1/2 bg-red-500 text-white rounded-full text-xs">
          {campaign.click_count_phone_number || '0'}
        </span>
      </button>

      <button
        onClick={() => onShare(campaign)}
        className="relative border-2 duration-200 ease-in-out inline-flex justify-center items-center transition font-medium text-xs w-24 h-8 text-white border-blue-600 bg-blue-600 hover:bg-blue-700 hover:border-blue-700 rounded-md p-1"
        aria-label="Share"
      >
     
        <span className="whitespace-nowrap text-xs">Paylaş</span>
        <svg className="w-4 h-4 mr-1" viewBox="0 0 1024 1024" fill="currentColor">
          <path d="M767.99994 585.142857q75.995429 0 129.462857 53.394286t53.394286 129.462857-53.394286 129.462857-129.462857 53.394286-129.462857-53.394286-53.394286-129.462857q0-6.875429 1.170286-19.456l-205.677714-102.838857q-52.589714 49.152-124.562286 49.152-75.995429 0-129.462857-53.394286t-53.394286-129.462857 53.394286-129.462857 129.462857-53.394286q71.972571 0 124.562286 49.152l205.677714-102.838857q-1.170286-12.580571-1.170286-19.456 0-75.995429 53.394286-129.462857t129.462857-53.394286 129.462857 53.394286 53.394286 129.462857-53.394286 129.462857-129.462857 53.394286q-71.972571 0-124.562286-49.152l-205.677714 102.838857q1.170286 12.580571 1.170286 19.456t-1.170286 19.456l205.677714 102.838857q52.589714-49.152 124.562286-49.152z" />
        </svg>
      </button>

      <button
        onClick={handleSignupClick}
        className="relative border-2 duration-200 ease-in-out inline-flex justify-center items-center transition font-medium text-xs w-24 h-8 text-white border-blue-600 bg-blue-600 hover:bg-blue-700 hover:border-blue-700 rounded-md p-1"
        aria-label="Register"
      >
        <span className="whitespace-nowrap text-xs">Kaydol</span>

        <svg className="w-4 h-4 mr-1" fill="currentColor" viewBox="0 0 20 20">
          <path fillRule="evenodd" d="M12.293 5.293a1 1 0 011.414 0l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-2.293-2.293a1 1 0 010-1.414z" clipRule="evenodd" />
        </svg>
      </button>
    </div>
  );
};

CampaignActions.propTypes = {
  campaign: PropTypes.shape({
    click_count: PropTypes.number.isRequired,
    click_count_phone_number: PropTypes.number,
    phone_number: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired,
  }).isRequired,
  onEmailClick: PropTypes.func.isRequired,
  onPhoneCall: PropTypes.func.isRequired,
  onShare: PropTypes.func.isRequired,
};

export default CampaignActions;
