import React, { useEffect, useState } from 'react';
import { fetchCampaigns } from '../redux/ApiCalls';
import LoopingSlider from '../components/LoopingSlider';
import CampaignList from '../components/CampaignList';
import Hero from '../components/home/Hero';
import NotificationWrapper from '../components/modal/notification-wrapper';

const Homepage = ({ darkMode ,campaigns,setCampaigns,loading,error}) => {
  // const [campaigns, setCampaigns] = useState([]);
  // const [loading, setLoading] = useState(true);
  // const [error, setError] = useState('');

  // useEffect(() => {
  //   const loadCampaigns = () => {
  //     fetchCampaigns()
  //       .then((response) => {
  //         setCampaigns(response.data);
  //         setLoading(false);
  //       })
  //       .catch(() => {
  //         setError('Bakım ve Güncelleme Yapılıyor');
  //         setLoading(false);
  //       });
  //   };
  //   loadCampaigns();
  // }, []);

  return (
    <div className={`${darkMode ? 'bg-[#091840]' : 'bg-white'} min-h-screen transition-colors`}>
      <Hero darkMode={darkMode} />

        
        <div className="max-w-xl mx-auto">
        <div className={`w-full ${darkMode ? 'bg-white' : 'bg-white'}  transition-colors`}>

          <div className=" p-2">
            <h2 className={`w-full ${darkMode ? 'bg-[#ca113d]' : ' bg-[#091840]'} text-white text-1xl sm:text-2xl font-bold text-center py-2 px-2 mb-2 rounded-[5px]`}>
              ÖNE ÇIKAN KAMPANYALAR
            </h2>
            <LoopingSlider darkMode={darkMode} campaigns={campaigns} />
          </div>
        </div>

        <div className="p-2">
          <div className="max-w-xl mx-auto">
            <div className={` mb-8`}>
           
              <h2 className={`w-full ${darkMode ? 'bg-[#ca113d]' : ' bg-[#091840]'} text-white text-1xl sm:text-2xl font-bold text-center py-2 px-2 mb-2 rounded-[5px]`}>
              TÜM KAMPANYALAR
            </h2>
              {loading ? (
                <div className="flex justify-center py-4">
                  <span className="loader " />
                </div>
              ) : (
                <CampaignList campaigns={campaigns} setCampaigns={setCampaigns} darkMode={darkMode} />
              )}
              {error && <p className="text-red-500">{error}</p>}
            </div>
          </div>
        </div>

      </div>
    </div>
  );
};

export default Homepage;
