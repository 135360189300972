import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import NextButton from './buttons/nextButton';
import { useSelector } from 'react-redux';

const LoopingSlider = ({  darkMode }) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [media, setMedia] = useState([]);
  const [titles, setTitles] = useState([]);
  const [contents, setContents] = useState([]);
  const [detailLinks, setDetailLinks] = useState([]);
  const navigate = useNavigate();
  const campaigns = useSelector((state) => state.campaignReducer.campaigns) || [];
  useEffect(() => {
    if (Array.isArray(campaigns)) {
      const updatedMedia = campaigns.flatMap(campaign => {
        if (campaign.media && campaign.media.length > 0) {
          const firstMedia = campaign.media[0];
          const mediaType = firstMedia.type || (firstMedia.file.endsWith('.mp4') ? 'video' : 'image');
          return {
            id: firstMedia.id,
            url: firstMedia.file.split('?')[0],
            type: mediaType 
          };
        }
        return [];
      });
    
      const updatedTitles = campaigns.map(campaign => campaign.title || "");
      const updatedContents = campaigns.map(campaign => campaign.content || "");
      const updatedDetailLinks = campaigns.map(campaign => campaign.id);
    
      setMedia(updatedMedia);
      setTitles(updatedTitles);
      setContents(updatedContents);
      setDetailLinks(updatedDetailLinks);
      setCurrentIndex(0);
    }
  }, [campaigns]);
  const nextSlide = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % media.length);
  };

  const prevSlide = () => {
    setCurrentIndex((prevIndex) => (prevIndex - 1 + media.length) % media.length);
  };

  const handleBoxClick = (campaignId) => {
    navigate(`/campaigns/${campaignId}`);
  };

  useEffect(() => {
    const interval = setInterval(nextSlide, 3000);
    return () => clearInterval(interval);
  }, [media]);

  

  console.log(media[currentIndex]?.type );

  return (
    <div className={`relative h-96 mx-autooverflow-hidden  rounded-[5px]    `}>
      <button
        className={`absolute top-1/2 left-2 transform -translate-y-1/2  bg-opacity-20 text-2xl z-10   `}
        onClick={prevSlide}
      >
        <KeyboardArrowLeftIcon />
      </button>
      <div className="w-full h-full ">
      <div className="w-full h-full">
  {media.length > 0 && (
    <div className="relative w-full h-full">
      {media[currentIndex].type === 'video' ? (
        <video
          src={media[currentIndex].url}
          className="w-full h-full object-cover rounded-[5px]"
          onError={() => {
            console.error(`Failed to load video at URL: ${media[currentIndex].url}`);
            // Optional: add fallback or proceed to the next slide
            nextSlide();
          }}
          autoPlay
          muted
          loop
        />
      ) : (
        <img
          src={media[currentIndex].url}
          alt={`Slide ${currentIndex + 1}`}
          className="w-full h-full object-cover rounded-[5px]"
          onError={() => {
            console.error(`Failed to load image at URL: ${media[currentIndex].url}`);
            // Optional: add fallback or proceed to the next slide
            nextSlide();
          }}
        />
      )}
      <div className={`absolute top-0 text-white font-bold p-2 leading-[1]`}>
        {titles[currentIndex]}
      </div>
      <div className={`absolute bottom-0 text-white font-bold pb-10 p-2`}>
        <p className="line-clamp-2 leading-[1]">{contents[currentIndex]}</p>
      </div>
      <div className="absolute bottom-0 right-0">
        <NextButton onClick={() => handleBoxClick(detailLinks[currentIndex])} darkMode={darkMode} />
      </div>
    </div>
  )}
</div>
      </div>
      <button
        className={`absolute top-1/2 right-2 transform -translate-y-1/2 ${darkMode ? 'bg-gray-700' : 'bg-white'} bg-opacity-20 text-2xl p-2 z-10  rounded-lg `}
        onClick={nextSlide}
      >
        <ChevronRightIcon />
      </button>
    </div>
  );
};

export default LoopingSlider;
