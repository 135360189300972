import axios from 'axios';

const baseUrl = process.env.REACT_APP_REST_URL;

const REGISTER = `${baseUrl}users/register/`;
const FETCH_CONTACT_SUBMISSIONS = `${baseUrl}mail/contact-submissions/`;
const CAMPAIGN_FETCH_LIST = `${baseUrl}campaign/list/`;
const CAMPAIGN_CREATE_URL = `${baseUrl}campaign/create/`;

const LOGIN_URL = `${baseUrl}users/login/`;
const REFRESH_TOKEN_URL = `${baseUrl}token/refresh/`;
const USER_DETAILS_URL = `${baseUrl}users/detail/`; 
const USER_CAMPAIGNS_URL = `${baseUrl}campaign/`; 
const USER_PROFILE_URL = `${baseUrl}users/profile/`;
const USER_UPDATE_PROFILE_URL= `${baseUrl}users/update-profile/`;

const CAMPAIGN_GENERATE_MAIL = `${baseUrl}ai/generate_campaign_mail/`;


const createUser = ({ username, email,  first_name,last_name,password, password2 }) => {
  const header = {
    'Content-Type': 'application/json',
  };
  return axios.post(
    REGISTER,
    { username,last_name,first_name, email, password, password2 },
    { headers: header }
  );
};

const login = ({ email, password }) => {
  const header = {
    'Content-Type': 'application/x-www-form-urlencoded',
  }

  const params = new URLSearchParams()
  params.append('email', email)

  params.append('password', password)

  return axios.post(LOGIN_URL, params, {
    headers: header,
  })
}

const fetchUserDetails = (userId) => {
  return axios.get(`${USER_DETAILS_URL}${userId}/`);
};

const fetchUserCampaigns = (userId) => {
  return axios.get(`${USER_CAMPAIGNS_URL}${userId}/`);
};
 
// Profil bilgilerini almak için API çağrısı
const fetchUserProfile = (token) => {
  const header = {
    'Authorization': `Bearer ${token}`,
    'Content-Type': 'application/json',
  };
  return axios.get(USER_PROFILE_URL, { headers: header });
};

// Profil bilgilerini güncellemek için API çağrısı
const updateUserProfile = (data, token) => {
  const header = {
    'Authorization': `Bearer ${token}`,
    'Content-Type': 'multipart/form-data',
  };
  return axios.put(USER_UPDATE_PROFILE_URL, data, { headers: header });
};

// Profil resmi yüklemek için API çağrısı (Opsiyonel)
const uploadProfilePicture = (formData, token) => {
  const header = {
    'Authorization': `Bearer ${token}`,
    'Content-Type': 'multipart/form-data',
  };
  return axios.post(`${USER_PROFILE_URL}/upload-picture/`, formData, { headers: header });
};

const refreshToken = async (refreshToken) => {
  try {
    const response = await axios.post(
      REFRESH_TOKEN_URL,
      { refresh: refreshToken },
      { headers: { 'Content-Type': 'application/json' } }
    );
    return response.data;
  } catch (error) {
    console.error('Error refreshing token:', error);
    throw error;
  }
};

const fetchContactSubmissions = () => {
  return axios.get(FETCH_CONTACT_SUBMISSIONS);
};

const createCampaign = (formData, token) => {
  return axios.post(CAMPAIGN_CREATE_URL, formData, {
    headers: { 
      'Authorization': `Bearer ${token}`,
      'Content-Type': 'multipart/form-data',
    }
  });
};

const generateCampaignMail = async (campaignData) => {
  try {
    const response = await axios.post(CAMPAIGN_GENERATE_MAIL, campaignData, {
      headers: {
        'Content-Type': 'application/json',
      },
    });
    return response.data; 
  } catch (error) {
    throw error; 
  }
};

const updateCampaign = (campaignId, formData, token) => {
  return axios.put(`${baseUrl}campaign/${campaignId}/edit/`, formData, {
    headers: {
      'Authorization': `Bearer ${token}`,
      'Content-Type': 'multipart/form-data',
    },
  });
};

const fetchCampaigns = () => {
  return axios.get(CAMPAIGN_FETCH_LIST);
};


const fetchCampaignById = (id) => {
  return axios.get(`${baseUrl}campaign/${id}/`);
};



const trackClick = (campaignId) => {
  
  return axios.post(`${baseUrl}campaign/track_click/`, { campaign_id: campaignId });
};

const trackClickPhone = (campaignId) => {
  return axios.post(`${baseUrl}campaign/track_click_phone/`, { campaign_id: campaignId });
};

const approveCampaign = (campaignId) => {
  return axios.patch(`${baseUrl}${campaignId}/approve/`);
};


const deleteCampaign = (campaignId, token) => {
  console.log(campaignId,"campaignId")
  return axios.delete(`${baseUrl}campaign/${campaignId}/delete/`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

export {
  createUser,
  fetchUserProfile,
  updateUserProfile,
  uploadProfilePicture,
  fetchContactSubmissions,
  fetchCampaigns,
  createCampaign,
  generateCampaignMail,
  updateCampaign,
  login,
  refreshToken,
  fetchCampaignById,
  trackClick,
  deleteCampaign,
  fetchUserDetails,
  fetchUserCampaigns,
  trackClickPhone,
  approveCampaign
};
