import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { fetchCampaigns, trackClick, trackClickPhone } from '../redux/ApiCalls';
import { loadCSS } from 'fg-loadcss';
import storeConstants from '../redux/constans/actionTypes';
import { useDispatch, useSelector } from 'react-redux';
import { MenuItem, Select, InputLabel, FormControl, Typography } from '@mui/material';
import UserInfoModal from './UserInfoModal';
import CampaignCard from './CampaignCard';

import demoImg from '../assets/images/demo.jpg'

const categoriesList = [
  'Hayvan Hakları',
  'Çevre',
  'Ceza Adaleti',
  'Çocuk ve Aile Refahı',
  'Din',
  'Eğitim',
  'Engelliler',
  'Göçmenler',
  'Kadın Hakları',
  'Kamu Politikası',
  'Kültür ve Sanat ve Medya',
  'Cinsiyet Eşitsizliği',
  'Politika',
  'Sağlık',
  'Spor',
  'Teknoloji ve İnternet',
  'Yaşlı Hakları',
  'Diğer'
];

const CampaignList = ({ darkMode, campaigns, setCampaigns }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [filteredCampaigns, setFilteredCampaigns] = useState([]);
  const [filters, setFilters] = useState({ category: '' });
  const token = useSelector((state) => state?.users?.token);
  const [selectedCampaign, setSelectedCampaign] = useState(null);
  const [isDataLoading, setIsDataLoading] = useState(true);

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');



  useEffect(() => {
    const node = loadCSS(
      'https://use.fontawesome.com/releases/v5.14.0/css/all.css',
      document.querySelector('#font-awesome-css') || document.head.firstChild
    );

    return () => {
      node.parentNode.removeChild(node);
    };
  }, []);



  const handleFilterChange = (e) => {
    const { name, value } = e.target;
    setFilters({ ...filters, [name]: value });
  };

  useEffect(() => {
    const { category } = filters;
    let filtered = campaigns;

    if (category) {
      filtered = filtered.filter(campaign => campaign.topic === category);
    }

    setFilteredCampaigns(filtered);
  }, [filters, campaigns]);

  const handleEmailClick = (campaign) => {
    setSelectedCampaign(campaign);
    dispatch({
      type: storeConstants.SET_USER_INFO_MODAL_OPEN,
      payload: true,
    });
  };

  console.log(selectedCampaign,"selectedCampaign")

  const handleConfirm = ({ first_name, last_name }) => {
    trackClick(selectedCampaign.id)
      .then(() => {
        const emailLink = `mailto:${selectedCampaign.email}?cc=${selectedCampaign.cc || ''}&bcc=${selectedCampaign.bcc || ''}&subject=${encodeURIComponent(selectedCampaign.subject || '')}&body=${encodeURIComponent(selectedCampaign.message || '')}%0A%0A ${first_name} ${last_name}`;
        window.location.href = emailLink;

        !token && (setTimeout(() => {
          navigate('/register');
        }, 1000));
      })
      .catch((error) => console.error('Error tracking click:', error));
  };

  const handlePhoneCall = (phoneNumber, campaignId) => {
    trackClickPhone(campaignId);
    if (phoneNumber) {
      window.location.href = `tel:${phoneNumber}`;
    } else {
      console.error("No phone number available");
    }
  };

  const handleBoxClick = (campaignId) => navigate(`/campaigns/${campaignId}`);
  const handleDetailUser = (userId) => navigate(`/user/${userId}`);

  const handleShare = (campaign) => {
    const shareTitle = campaign.title || 'Başlık Yok';
    const shareUrl = `${window.location.origin}/campaigns/${campaign.id}`;
    const shareImageUrl = demoImg;

    if (navigator.share) {
      navigator.share({
        title: shareTitle,
        text: `${shareTitle}\n${campaign.content || 'İçerik Yok'}`,
        url: shareUrl,
      })
        .then(() => console.log('Paylaşım başarılı.'))
        .catch((error) => {
          console.error('Paylaşım hatası:', error);
          alert('Paylaşım sırasında bir hata oluştu.');
        });
    } else {
      const shareText = `${shareTitle}\n${campaign.content || 'İçerik Yok'}\n${shareUrl}\n\n${shareImageUrl}`;
      navigator.clipboard.writeText(shareText)
        .then(() => alert('Kampanya bağlantısı ve görsel panoya kopyalandı!'))
        .catch((error) => {
          console.error('Panoya kopyalama hatası:', error);
          alert('Panoya kopyalama sırasında bir hata oluştu.');
        });
    }
  };

  return (
    <div className={` ${darkMode ? 'bg-[#091840] text-white' : 'bg-white text-gray-900'}`}>
      <FormControl fullWidth variant="outlined" className={`border ${darkMode ? 'border-[#b92d41]' : 'border-[#ccc]'}`} margin="normal" darkMode={darkMode} >
        <InputLabel className={darkMode ? 'text-white' : 'text-gray-900'}>Kategoriye göre ara</InputLabel>
        <Select
          name="category"
          value={filters.category}
          onChange={handleFilterChange}
          label="Kategoriye göre ara"
          className={`bg-transparent ${darkMode ? 'text-white' : 'text-gray-900'} border focus:outline-none focus:ring-0`}
          MenuProps={{
            PaperProps: {
              className: `${darkMode ? 'bg-[#091840] text-white' : 'bg-white text-gray-900'}`,
            },
          }}
          IconComponent={() => (
            <svg className="w-5 h-5" viewBox="0 0 20 20" fill="currentColor">
              <path d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 011.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" />
            </svg>
          )}
        >
          <MenuItem value="">Tüm Kategoriler</MenuItem>
          {categoriesList.map((category) => (
            <MenuItem key={category} value={category}>
              {category}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      {filteredCampaigns.length > 0 ? (
        filteredCampaigns.map((campaign, index) => (
          <div
            key={campaign.id}
            className={`py-4 ${index < filteredCampaigns.length - 1 ? 'border-b border-gray-300' : ''}`}
          >
            <CampaignCard
              campaign={campaign}
              onEmailClick={handleEmailClick}
              onPhoneCall={handlePhoneCall}
              onDetailUser={handleDetailUser}
              onShare={handleShare}
              show={true}
              darkMode={darkMode}
              isLoading={isDataLoading}
              setIsDataLoading={setIsDataLoading}
            />
          </div>
        ))
      ) : (
        <Typography variant="h6" color="text.secondary" style={{ textAlign: 'center', marginTop: '20px' }}>
          Bulunan Kampanya Yok
        </Typography>
      )}
      <UserInfoModal onConfirm={handleConfirm} />
    </div>
  );
};

export default CampaignList;
