// import React, { useEffect, useState } from 'react';
// import WhatsAppIcon from "@mui/icons-material/WhatsApp";
// import FlagsSelect from 'react-flags-select';

// const WPBand = ({ darkMode }) => {
//   const [selected, setSelected] = useState(''); 
//   const [loading, setLoading] = useState(true); 

//   useEffect(() => {
//     const getUserCountry = async () => {
//       try {
//         const response = await fetch('https://ipapi.co/json/');
//         const data = await response.json();
//         const userCountry = data.country;
//         const langMap = { US: 'en', TR: 'tr' };
//         const initialLang = userCountry in langMap ? userCountry : 'TR';
        
//         setSelected(initialLang);
//         localStorage.setItem('selectedLang', JSON.stringify({ selectedLang: langMap[initialLang] }));
//       } catch (error) {
//         console.error('Failed to get user location:', error);
//         setSelected('TR');
//       } finally {
//         setLoading(false);
//       }
//     };
//     getUserCountry();
//   }, []);
//   const handleLanguageChange = (code) => {
//     const langMap = { US: 'en', TR: 'tr' };
//     const selectedLang = langMap[code];

//     setSelected(code);
//     localStorage.setItem('selectedLang', JSON.stringify({ selectedLang }));

//     window.location.reload();
//   };

//   return (
    // <div className={`w-full fixed top-0 z-50 ${darkMode ? 'bg-[#091840]' : 'bg-white'}`}>
    //   <div className="mx-auto max-w-4xl">
    //     <div className={`flex justify-end gap-2 pl-2`}>
//           <div className={`text-xs content-center font-semibold ${darkMode ? 'text-white' : 'text-gray-600'}`}>
//             En son eklenen kampanyalardan haberdar olmak için&nbsp;
//             <a
//               href="https://whatsapp.com/channel/0029Vaj9zTmInlqOtmTtnC2m"
//               target="_blank"
//               rel="noopener noreferrer"
//               className={`underline relative inline-flex text-xs font-semibold content-center ${darkMode ? 'text-green-400 hover:text-green-300' : 'text-green-600 hover:text-green-500'}`}
//             >
//               bizi takip edebilirsiniz
//             </a>
//           </div>
//           <WhatsAppIcon className={`text-md m-2 content-center ${darkMode ? 'text-green-400' : 'text-green-600'}`} />
//           <FlagsSelect
//               selected={selected}
//               onSelect={handleLanguageChange}
//               countries={["US", "TR"]}
//               customLabels={{ US: "EN-US", TR: "TR" }}
//               className="  inline-flex text-black z-70"
//               // Özel stiller
//               placeholder="Select Language" // Placeholder ekleyin
//               optionsSize={10} // Seçenek boyutunu ayarlayın
//               selectedSize={12} // Seçilen boyutunu ayarlayın
//               styles={{
//                 zIndex: 100,
//                 option: (base) => ({
//                   ...base,
//                   zIndex: "100 !important", // Option için z-index'i artırın
//                 }),
//                 dropdown: (base) => ({
//                   ...base,
//                   zIndex: "100 !important", // Dropdown için z-index'i artırın
//                   position: 'absolute',
//                   top: '100%', // Dropdown'un konumunu ayarlayın
//                 }),
//               }}
//               disabled
//             />
//         </div>
       
//       </div>
//     </div>
//   );
// };

// export default WPBand;
import React, { useEffect, useState } from 'react';
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import FlagsSelect from 'react-flags-select';

const WPBand = ({ darkMode }) => {
  const [selected, setSelected] = useState(''); 
  const [loading, setLoading] = useState(true); 

  useEffect(() => {
    const getUserCountry = async () => {
      try {
        const response = await fetch('https://ipapi.co/json/');
        const data = await response.json();
        const userCountry = data.country;
        const langMap = { US: 'en', TR: 'tr' };
        const initialLang = userCountry in langMap ? userCountry : 'TR';
        
        setSelected(initialLang);
        localStorage.setItem('selectedLang', JSON.stringify({ selectedLang: langMap[initialLang] }));
      } catch (error) {
        console.error('Failed to get user location:', error);
        setSelected('TR');
      } finally {
        setLoading(false);
      }
    };
    getUserCountry();
  }, []);

  const handleLanguageChange = (code) => {
    const langMap = { US: 'en', TR: 'tr' };
    const selectedLang = langMap[code];

    setSelected(code);
    localStorage.setItem('selectedLang', JSON.stringify({ selectedLang }));

    window.location.reload();
  };
  

  

  return (
    <div className={`w-full fixed top-0 z-50 ${darkMode ? 'bg-[#091840]' : 'bg-white'}`}>
      <div className="mx-auto w-full">
        <div className={`flex justify-end  gap-2 w-full pl-2`}>
        <div className={`text-xs sm:text-xs md:text-sm flex items-center justify-center md:justify-start font-semibold ${darkMode ? 'text-white' : 'text-gray-600'}`}>
  En son eklenen kampanyalardan haberdar olmak için&nbsp;
  <a
    href="https://whatsapp.com/channel/0029Vaj9zTmInlqOtmTtnC2m"
    target="_blank"
    rel="noopener noreferrer"
    className={`underline inline-flex text-xs sm:text-xs md:text-sm font-semibold ${darkMode ? 'text-green-400 hover:text-green-300' : 'text-green-600 hover:text-green-500'}`}
  >
    bizi takip edebilirsiniz
  </a>
  <a 
    href="https://whatsapp.com/channel/0029Vaj9zTmInlqOtmTtnC2m" 
    target="_blank" 
    rel="noopener noreferrer"
    className="ml-1"
  >
    <WhatsAppIcon className={`text-xl md:xl m-1 ${darkMode ? 'text-green-400' : 'text-green-600'}`} />
  </a> 
</div>


         <FlagsSelect
              selected={selected}
              onSelect={handleLanguageChange}
              countries={["US", "TR"]}
              customLabels={{ US: "EN-US", TR: "TR" }}
              className="inline-flex text-black z-70"
              placeholder="Select Language"
              optionsSize={10}
              selectedSize={12}
              styles={{
                zIndex: 100,
                option: (base) => ({
                  ...base,
                  zIndex: "100 !important",
                }),
                dropdown: (base) => ({
                  ...base,
                  zIndex: "100 !important",
                  position: 'absolute',
                  top: '100%',
                }),
              }}
              disabled
            />
        </div>
      </div>
    </div>
  );
};

export default WPBand;
